import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerContact,ButtonParams } from '@shared/models';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { SidebarParams } from '@shared/models/sidebar-params.model';
import { ContactPersonService } from '../contact-person.service';
import { Client, Contact } from '@entities/client/client.model';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { AlertType } from '@shared/models/alert-type.enum';
import { Project } from '@entities/project/project.model';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent extends SflBaseComponent implements OnInit {
  @Output() closeSidebarEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() updateTheClientList: EventEmitter<CustomerContact> = new EventEmitter();
  @Input() sidebarParams: SidebarParams<CustomerContact>;
  @Input() client: Client;
  @Input() selectedContact: Contact;

  @Input() project: Project;
  cardTitle: string;
  addorUpdateButton: string;
  cardSubTitle = null;
  buttons: ButtonParams[] = [
    {
      btnClass: 'btn-close-icon',
      btnIcon: 'times',
      action: this.onClose.bind(this)
    }
  ];
  createContactForm: FormGroup;
  constructor(private readonly contactPersonService: ContactPersonService, private readonly layoutUtilsService: LayoutUtilsService) {
    super();
  }

  initForm() {
    this.createContactForm = new FormGroup({
      name: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      phone: new FormControl(''),
      email: new FormControl(''),
      is_primary: new FormControl(true),
      id: new FormControl(''),
    });

    if (this.client?.isEdit || this.project?.isEdit) {
      this.patchValuesForContact();
      this.cardTitle = 'Update Contact';
      this.addorUpdateButton = 'Update';
    }
  }
  patchValuesForContact() {
    if (this.selectedContact) {
      this.createContactForm.patchValue(this.selectedContact.contact);
    }
  }

  ngOnInit(): void {
    this.cardTitle = 'Add Contact';
    this.addorUpdateButton = 'Add';
    this.initForm();
  }

  onClose(customerContact?: CustomerContact) {
    this.isSubmitting = false;
    this.closeSidebarEvent.next(true);
    if (customerContact) {
      this.updateTheClientList.next(customerContact);
    }
  }

  onSubmit() {
    if(!this.checkFormForValidation(this.createContactForm)){
      if (this.client?.isEdit || this.project?.isEdit) {
        this.updateContact();
      } else {
        this.addContact();
      }
    }
  }

  addContact() {
    const createContactParam: CustomerContact = this.createContactForm.value;
    let addContactService = ''
    if (this.project) {
      createContactParam.project_id = this.project.project.id;
      addContactService = 'createProjectContact'
    } else {
      createContactParam.customer_id = this.client.customer.id;
      addContactService = 'createContact'
    }

    this.subscriptionManager.add(this.contactPersonService[addContactService](this.createContactForm.value).subscribe(res => {
      this.layoutUtilsService.showActionNotification('Contacts details added successfully', AlertType.Success);
      this.onClose(res);
    }, () => {
      this.isSubmitting = false;
      this.layoutUtilsService.showActionNotification('There was an error while adding contacts details', AlertType.Error);
    }));
  }
  updateContact() {
    this.isSubmitting = true;
    const createContactParam: CustomerContact = this.createContactForm.value;
    let updateContactService = '';
    if (this.project) {
      updateContactService = 'updateProjectContact';
      createContactParam.project_id = this.project.project.id;
    } else {
      updateContactService = 'updateContact';
    }
    this.subscriptionManager.add(this.contactPersonService[updateContactService](this.createContactForm.value, this.createContactForm.value.id).subscribe(res => {
      this.layoutUtilsService.showActionNotification('Contacts details updated successfully', AlertType.Success);
      this.onClose(res);
    }, () => {
      this.isSubmitting = false;
      this.layoutUtilsService.showActionNotification('There was an error while updating contacts details', AlertType.Error);
    }));

  }
}
