<div class="card card-custom add-contact">
  <app-card-header [cardTitle]="cardTitle" [cardSubTitle]="cardSubTitle" [buttons]="buttons" [cardLabelClass]="'mb-0'"></app-card-header>
  <div class="card-body create-card">
    <form class="form" [formGroup]="createContactForm" autocomplete="off" novalidate="novalidate" id="createContactForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-label">Contact Name</label>
            <input type="email" class="form-control custom" required placeholder="Contact Name" formControlName="name" minlength="5" maxlength="255">
            <app-form-error [validation]="'required'" [form]="createContactForm" [controlName]="'name'" [fieldLabel]="'Contact Name'"></app-form-error>
            <app-form-error [validation]="'minlength'" [form]="createContactForm" [controlName]="'name'" [fieldLabel]="'Contact Name'"></app-form-error>
            <app-form-error [validation]="'maxlength'" [form]="createContactForm" [controlName]="'name'" [fieldLabel]="'Contact Name'"></app-form-error>
          </div>
          <div class="form-group mt-1 mb-1">
            <label class="form-label">Title</label>
            <input type="email" class="form-control custom" required placeholder="e.g. CTO" formControlName="title" minlength="3" maxlength="255">
            <app-form-error [validation]="'required'" [form]="createContactForm" [controlName]="'title'" [fieldLabel]="'Title'"></app-form-error>
            <app-form-error [validation]="'minlength'" [form]="createContactForm" [controlName]="'title'" [fieldLabel]="'Title'" [length]="3"></app-form-error>
            <app-form-error [validation]="'maxlength'" [form]="createContactForm" [controlName]="'title'" [fieldLabel]="'Title'"></app-form-error>
          </div>
          <div class="form-group">
            <label class="form-label">Phone</label>
            <input type="phone" sflIsPhone class="form-control custom" placeholder="Contact Phone" formControlName="phone">
          </div>
          <div class="form-group">
            <label class="form-label">Email</label>
            <input type="email" class="form-control custom" placeholder="Contact Email" formControlName="email" email>
            <app-form-error [validation]="'email'" [form]="createContactForm" [controlName]="'email'" [fieldLabel]="'Email'"></app-form-error>
          </div>
          <div class="form-group">
            <input type="checkbox" formControlName="is_primary" id="isPrimary" class="form-check" />
            <label for="isPrimary" class="label-form-check">Add as Primary Contact</label>
          </div>
        </div>
      </div>
      <div class="form-group d-flex flex-wrap justify-content-end align-items-center mt-2">
        <button id="addContactCancel" type="button" class="btn-cancel" (click)="onClose()">Cancel</button>
        <button id="addContactSubmit" type="submit" [isSubmitting]="isSubmitting" class="btn-save">{{addorUpdateButton}}</button>
      </div>
    </form>
  </div>
</div>
