import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerContact } from '@shared/models';
import { SidebarParams } from '@shared/models/sidebar-params.model';
import { SflBaseComponent } from '../sfl-base/sfl-base.component';

interface CustomerContactResponse {
  contact: CustomerContact
}
@Component({
  selector: 'app-contact-person',
  templateUrl: './contact-person.component.html',
  styleUrls: ['./contact-person.component.scss']
})
export class ContactPersonComponent extends SflBaseComponent implements OnInit {
  @Input() projectContact = false;
  @Input() contactPersons: CustomerContactResponse[];
  @Input() sidebarParams: SidebarParams<CustomerContactResponse>;
  @Output() updateContactSideNav: EventEmitter<SidebarParams<CustomerContactResponse>> = new EventEmitter();
  @Output() showDeleteModal = new EventEmitter();
  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  openEditSideNav(sidebarParams: SidebarParams<CustomerContactResponse>) {
    this.updateContactSideNav.emit(sidebarParams);
  }

  showDeleteConfirmModal(contactPerson: CustomerContact) {
    this.showDeleteModal.emit(contactPerson);
  }
}
