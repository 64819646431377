// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from '@auth/auth.service';
import { APP_ROUTES } from '@shared/constants/routes.constant';
import { of, Subscription } from 'rxjs';
import { environment } from '@environment/environment'
const version = require('../../../../../../../package.json');
@Component({
  selector: 'kt-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  // Public properties
  user$: Observable<{ firstName, lastName }>;
  myProfile = APP_ROUTES.MY_PROFILE;
  @Input() userDropdownStyle = 'light';
  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;
  userEmail: string;
  currentApplicationVersion = version.version;
  productionFlag = environment.production;
  subscriptionManager: Subscription = new Subscription();
  backendVersion = '';
  currentRole = '';
  constructor(private readonly authService: AuthService) {
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.user$ = of({ lastName: 'User', firstName: 'Test' });
    this.userEmail = localStorage.getItem('userEmail');
      this.subscriptionManager.add(this.authService.getBackendBuildVersion().subscribe(res => {
        this.backendVersion = res?.data?.release_version?.version;
      }))
      this.currentRole = localStorage.getItem('role').toUpperCase();
  }

  /**
   * Log out
   */
  logout() {
    this.authService.logout();
  }

  openPrivacyPolicy() {
    window.open('https://www.ogantry.com/privacyPolicy.html');
  }
}
