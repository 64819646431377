import { Injectable, RendererFactory2, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class SFLTitleProvider {
  renderer: Renderer2 = null;

  constructor(
    private readonly translateService: TranslateService,
    private readonly rootRenderer: RendererFactory2,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly metaTagService: Meta
  ) {
    this.renderer = this.rootRenderer.createRenderer(
      document.querySelector('html'),
      null
    );
  }

  /**
   * Update the window title using params in the following
   * order:
   * 1. titleKey parameter
   * 2. $state.$current.data.pageTitle (current state page title)
   * 3. 'global.title'
   */
  updateTitle() {
    const titleKey = this.getPageTitle(this.router.routerState.snapshot.root);
    this.translateService.get(titleKey).subscribe((title) => {
      this.titleService.setTitle(title);
    });
    const descriptionKey = this.getPageDescription(
      this.router.routerState.snapshot.root
    );
    this.translateService.get(descriptionKey).subscribe((description) => {
      this.metaTagService.updateTag({
        name: 'description',
        content: description,
      });
    });
  }

  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
    let title: string =
      routeSnapshot.data && routeSnapshot.data['pageTitle']
        ? routeSnapshot.data['pageTitle']
        : 'SFL Base Project';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  private getPageDescription(routeSnapshot: ActivatedRouteSnapshot) {
    let description: string =
      routeSnapshot.data && routeSnapshot.data['pageDescription']
        ? routeSnapshot.data['pageDescription']
        : 'SFL Base Project';
    if (routeSnapshot.firstChild) {
      description =
        this.getPageDescription(routeSnapshot.firstChild) || description;
    }
    return description;
  }
}
