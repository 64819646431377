<p-listbox
  [options]="frozenCols"
  [(ngModel)]="selectedColumns"
  [checkbox]="true"
  [filter]="false"
  [multiple]="true"
  optionLabel="monthLabel"
  [listStyle]="{ 'max-height': '250px' }"
  [style]="{ width: '15rem' }"
  (onChange)="columnChangeHold = $event"
>
  <ng-template let-country pTemplate="item">
    <div class="country-item">
      <div>{{ country?.[dynamicBindingKey] }}</div>
    </div>
  </ng-template>
</p-listbox>
<div class="mt-5 pb-3 mx-2">
  <button
    class="btn btn-sm btn-primary"
    (click)="onSelectColumsChange(columnChangeHold)"
  >
    Done
  </button>
  <button class="btn btn-sm btn-primary ml-2" (click)="onSelectColumsChange()">
    Cancel
  </button>
</div>
