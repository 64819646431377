// Angular
import { AfterViewInit, Component, OnInit } from '@angular/core';
// Layout
import { HtmlClassService } from '../html-class.service';
import { LayoutConfigService } from '@shared/services/layout-config.service';
import { ToggleOptions } from '@shared/directives/toggle.directive';

@Component({
  selector: 'kt-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit, AfterViewInit {
  // Public properties
  headerLogo = '';
  brandClasses = '';
  asideSelfMinimizeToggle = true;

  toggleOptions: ToggleOptions = {
    target: 'kt_body',
    targetState: 'aside-minimize',
    toggleState: 'active'
  };

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   * @param htmlClassService: HtmlClassService
   */
  constructor(private readonly layoutConfigService: LayoutConfigService, public readonly htmlClassService: HtmlClassService) {
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.headerLogo = this.getAsideLogo();
    this.brandClasses = this.htmlClassService.getClasses('brand', true).toString();
    this.asideSelfMinimizeToggle = this.layoutConfigService.getConfig('aside.self.minimize.toggle');
  }

  /**
   * On after view init
   */
  ngAfterViewInit(): void {
  }

  getAsideLogo() {
    return `./assets/media/logos/ogantryLogo.png`;
  }

  toggleAsideClick() {
    document.body.classList.toggle('aside-minimize');
  }
}
