import { Subscription } from "rxjs/internal/Subscription";
// Angular
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
// Layoutv
// language list
import { locale as enLang } from "@shared/_config/i18n/en";
import { TranslationService } from "@shared/services/translation.service";
import { LayoutConfigService } from "@shared/services/layout-config.service";
import { SplashScreenService } from "@shared/services/splash-screen.service";
import { AppConstants } from "@shared/constants";
import { CheckUpdateService } from "@shared/services/check-update.service";
import { KtDialogService } from "@shared/services";
import { MatSnackBar } from '@angular/material/snack-bar';


// import $ from 'jquery';
export let browserRefresh = false;

@Component({
  // tslint:disable-next-line:component-selector
  selector: "body[kt-root]",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = "OGantry";
  loader: boolean;
  subscriptionManager: Subscription = new Subscription();
  private readonly unsubscribe: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   * @param layoutConfigService: LayoutConfigService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private readonly translationService: TranslationService,
    private readonly router: Router,
    private readonly layoutConfigService: LayoutConfigService,
    private readonly splashScreenService: SplashScreenService,
    private readonly checkUpdateService: CheckUpdateService,
    private readonly route: ActivatedRoute,
    private readonly ktDialogService: KtDialogService,
    private readonly snackBar: MatSnackBar
  ) {
    // register translations
    this.translationService.loadTranslations(enLang);
      window.addEventListener('resize', () => {
        this.setHeight();
      });
      this.subscriptionManager.add(this.layoutConfigService.updateHeight$.subscribe((update) => {
        if(update){
          this.setHeight();
        }
        }));
  }
  setHeight(){
      // First get the viewport height
      const headerElements = Array.from(document.getElementsByClassName('fixed-content'));
      let headerHeight = 0;
      for (const header of headerElements) {
        headerHeight += header.clientHeight;
      }
      const vh = (window.innerHeight - (headerHeight || 0)) * 0.01;
      document.documentElement.style.setProperty('--fixed-content-height', `${vh}px`);
    }
  /**
   * On init
   */
  ngOnInit(): void {
    // enable/disable loader
    this.loader = this.layoutConfigService.getConfig("page-loader.type");
    // check for updates
    this.checkUpdateService.checkForUpdates();
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add("page-loaded");
        }, AppConstants.setTimeOut);
      }
      if (event instanceof NavigationStart) {
        browserRefresh = !this.router.navigated;
        this.ktDialogService.hide();
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
  // ngAfterViewChecked() {
  //   (<any>$('[data-toggle="popover"]')).popover()
  // }
  
  @HostListener('window:storage', ['$event'])
  onStorageChange(event: StorageEvent): void {
    if (event.key === 'userEmail' && event.oldValue !== event.newValue ) {
      const snackBarRef = this.snackBar.open('You have logged in with new tenant in another tab', 'Refresh', { horizontalPosition: 'left', panelClass: ['custom-update-prompt'] });
      snackBarRef.onAction().subscribe(() => window.location.reload());
    }
  }  
}
