// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ISavedFilter, SaveFilter } from '@entities/client/client.model';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { ClientService } from '@entities/client/client.service';
import { AuthNoticeService } from '@auth/auth-notice/auth-notice.service';
import { AppMessages } from '@shared/constants';

interface IData {
  title: string;
  filterObject: SaveFilter;
  placeholder?: string;
}
@Component({
  selector: 'kt-save-filter-group-dialog',
  templateUrl: './save-filter-group-dialog.component.html'
})
export class SaveFilterGroupDialogComponent extends SflBaseComponent implements OnInit {
  filterForm: FormGroup;
  viewLoading = false;
  loadingAfterSubmit = false;
  constructor(public readonly dialogRef: MatDialogRef<SaveFilterGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IData,
    private readonly clientService: ClientService,
    private readonly authNoticeService: AuthNoticeService) {
    super();
  }

  ngOnInit() {
    // cleaning up the auth notice message as it might appear if previously set
    this.authNoticeService.setNotice('', '');
    /* Server loading imitation. Remove this */
    this.filterForm = new FormGroup({
      name: new FormControl('', Validators.required)
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveFilterGroup() {
    this.isSubmitting = true;
    this.checkFormForValidation(this.filterForm);
    this.data.filterObject.name = this.filterForm.controls['name'].value;
    this.subscriptionManager.add(this.clientService.createFilter(this.data.filterObject).subscribe((filterSaveRes: ISavedFilter) => {
      this.dialogRef.close(filterSaveRes.data);
      this.isSubmitting = false;
    }, (err) => this.onError(err)));
    this.clientService.saveFilterData$.next(true)
  }

  onError(error?) {
    // checking if the error is due to duplicate filter name
    if (error?.error?.error_code === 422 && error?.error?.errors?.includes('unique_name_user:')) {
      this.authNoticeService.setNotice(AppMessages.duplicateFilterName, 'danger');
    } else {
      this.authNoticeService.setNotice('There was an error while saving the filter', 'danger');
    }
    this.isSubmitting = false;
  }
}
