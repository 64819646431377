import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { ApiUrl } from "@shared/constants";
import { OGantryHttpResponse } from '@shared/models';
import { Client, ClientList, ISavedFilter } from './client.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  saveFilterData$ = new Subject<boolean>();
  showNewTags = new Subject<boolean>();
  
  constructor(private readonly http: HttpClient) { }

  createClient(client: Client): Observable<OGantryHttpResponse<Client>> {
    return this.http.post<OGantryHttpResponse<Client>>(ApiUrl.client, { customer: client });
  }

  updateClient(clientId: number, client: Client): Observable<OGantryHttpResponse<Client>> {
    return this.http.put<OGantryHttpResponse<Client>>(`${ApiUrl.client}/${clientId}`, { customer: client });
  }

  getClient(clientId: number): Observable<OGantryHttpResponse<Client>> {
    return this.http.get<OGantryHttpResponse<Client>>(`${ApiUrl.client}/${clientId}`);
  }

  getClientByName(queryFilter): Observable<HttpResponse<OGantryHttpResponse<ClientList>>> {
    return this.http.get<OGantryHttpResponse<ClientList>>(ApiUrl.client, { params: queryFilter, observe: 'response' });
  }

  getClientData(queryFilter: any): Observable<HttpResponse<OGantryHttpResponse<ClientList>>> {
    return this.http.get<OGantryHttpResponse<ClientList>>(ApiUrl.client, { params: queryFilter, observe: 'response' });
  }

  createFilter(requestObject): Observable<ISavedFilter> {
    return this.http.post<ISavedFilter>(ApiUrl.saveFilter, { query_filter: requestObject });
  }
  updateFilter(id,requestObject): Observable<ISavedFilter> {
    return this.http.put<ISavedFilter>(`${ApiUrl.saveFilter}/${id}`, { query_filter: requestObject.query_filter });
  }
  getStoredFilters(requestObject) {
    return this.http.get(ApiUrl.saveFilter, { params: requestObject });
  }

  deleteStoredFilters(id) {
    return this.http.delete(`${ApiUrl.saveFilter}/${id}`);
  }

  deleteCustomer(id:number) {
    return this.http.delete(`${ApiUrl.client}/${id}`);
  }
}
