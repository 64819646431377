<div class="PL-border" id="shared-range-selector">
  <div class="form-group">
    <label class="form-label background">
      <div class="width-34">Display Range</div>
      <div class="form-group pt-0 save-filter-radio">
        <div class="form-check form-check-inline mt-2">
          <input
            class="form-check-input"
            type="radio"
            name="displayRange"
            [checked]="rollingRadioBtnSelected"
            id="rolling"
            autocomplete="off"
            (click)="radioBtnSelected('rolling')"
          />
          <label class="mb-0" for="rolling"> Rolling </label>
        </div>
        <div class="form-check form-check-inline mt-2">
          <input
            class="form-check-input"
            type="radio"
            name="displayRange"
            [checked]="showDateRangeRadioButton"
            id="custom"
            autocomplete="off"
            (click)="radioBtnSelected('custom')"
          />
          <label class="mb-0" for="custom"> Custom </label>
        </div>
        <div class="form-check form-check-inline mt-2">
          <input
            class="form-check-input"
            type="radio"
            name="displayRange"
            [checked]="yearRadioBtnSelected"
            id="byYear"
            autocomplete="off"
            (click)="radioBtnSelected('year')"
          />
          <label class="mb-0" for="byYear"> Year </label>
        </div>
        <div class="form-check form-check-inline mt-2">
          <input
            class="form-check-input"
            type="radio"
            name="displayRange"
            id="byQuarter"
            [checked]="quaterRadioBtnSelected"
            autocomplete="off"
            (click)="radioBtnSelected('quarter')"
          />
          <label class="mb-0" for="byQuarter"> Quarter </label>
        </div>
      </div>
    </label>
  </div>
  <div *ngIf="rollingRadioBtnSelected">
    <div class="form-group dropdown p-2">
      <p-dropdown
        appendTo="body"
        [(ngModel)]="selectedRollingOption"
        (ngModelChange)="clearOtherDropdowns('rollingOption')"
        name="rollingOption"
        [options]="rollingOptionsArray"
        placeholder="Select"
      ></p-dropdown>
    </div>
  </div>
  <div *ngIf="showDateRangeRadioButton">
    <div class="row mt-4 p-2">
      <div class="col-6">
        <div class="form-group pb-0">
          <label class="form-label">Start Month</label>
        </div>
        <div class="form-group pt-0 range-calender">
          <p-calendar
            appendTo="body"
            view="month"
            [showIcon]="true"
            [(ngModel)]="start_month"
            [ngModelOptions]="{ standalone: true }"
            dateFormat="MM/yy"
            [yearNavigator]="true"
            yearRange="2000:2030"
            [readonlyInput]="true"
            inputId="monthpicker1"
            placeholder="Month/Year"
            (ngModelChange)="clearOtherDropdowns('satrt_month')"
          >
          </p-calendar>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group pb-0">
          <label class="form-label">End Month</label>
        </div>
        <div class="form-group pt-0 range-calender">
          <p-calendar
            appendTo="body"
            view="month"
            [showIcon]="true"
            [(ngModel)]="end_month"
            [ngModelOptions]="{ standalone: true }"
            dateFormat="MM/yy"
            [yearNavigator]="true"
            yearRange="2000:2030"
            [readonlyInput]="true"
            inputId="monthpicker2"
            placeholder="Month/Year"
            (ngModelChange)="clearOtherDropdowns('end_month')"
          >
          </p-calendar>
        </div>
      </div>
    </div>
    <div class="form-group pb-0" *ngIf="dateError">
      <small class="form-text text-danger">
        Start Month should be less than End Month</small
      >
    </div>
    <div class="form-group pb-0" *ngIf="dateRequired">
      <small class="form-text text-danger">
        Start Month and End Month is required</small
      >
    </div>
  </div>
  <div *ngIf="yearRadioBtnSelected">
    <div class="form-group first dropdown p-2">
      <p-dropdown
        appendTo="body"
        [(ngModel)]="selectedYear"
        (ngModelChange)="clearOtherDropdowns('year')"
        name="year"
        [options]="yearArray"
        placeholder="Select"
      ></p-dropdown>
    </div>
  </div>
  <div *ngIf="quaterRadioBtnSelected">
    <div class="form-group first dropdown p-2">
      <p-dropdown
        appendTo="body"
        [(ngModel)]="selectedQuarterValue"
        (ngModelChange)="clearOtherDropdowns('quarter')"
        name="quarter"
        [options]="quaterArray"
        placeholder="Select"
      ></p-dropdown>
    </div>
  </div>
</div>
