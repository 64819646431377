<div class="card card-custom" [ngClass]="{'card-body-progress' : viewLoading, 'card-body-progress card-body-progress-overlay' : loadingAfterSubmit }">
  <div class="card-header">
    <div class="card-title mb-0">
      <h3 class="card-label mb-0">{{data?.title}}</h3>
    </div>
  </div>
  <div class="card card-custom">
    <form class="form" [formGroup]="filterForm" (ngSubmit)="filterForm.valid && saveFilterGroup()">
      <div class="card-body">
        <kt-auth-notice></kt-auth-notice>
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="form-group first">
              <input class="form-control custom" [placeholder]="data?.placeholder || 'e.g. New York Group'" formControlName="name">
              <app-form-error [validation]="'required'" [form]="filterForm" [controlName]="'name'" [fieldLabel]="'Group name'"></app-form-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="d-flex justify-content-end">
              <div class="form-group d-flex flex-wrap mt-2">
                <button id="addContactCancel" type="button" class="btn-cancel" (click)="onNoClick()">Cancel</button>
                <button id="addContactSubmit" type="submit" class="btn-save" [isSubmitting]="isSubmitting">Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
