import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SflBaseComponent } from '../sfl-base/sfl-base.component';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html'
})
export class FormErrorComponent extends SflBaseComponent {
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() validation: string;
  @Input() fieldLabel: string;
  @Input() length?: string;
  constructor() {
    super();
  }

  get errorMessage(): string {
    switch (this.validation) {
      case 'required':
        return `${this.fieldLabel} is required`;
      case 'minlength':
        return `${this.fieldLabel} must be at least ${this.length || '5'} characters in length`;
      case 'maxlength':
        return `${this.fieldLabel} cannot be more than ${this.length || '255'} characters in length`;
      case 'email':
        return `${this.fieldLabel} is invalid`;
      case 'max':
        return `${this.fieldLabel} can have max value of ${this.length || '8'}`;
      case 'min':
        return `${this.fieldLabel} can have min value of ${this.length || '1'}`;
      case 'forbiddenName':
        return `project_expenses is not allowed as position name`;
    }
  }


}
