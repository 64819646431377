// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns only first letter of string
 */
@Pipe({
  name: 'addCommasToNumbers'
})
export class AddCommasToNumbersPipe implements PipeTransform {

  /**
   * Transform
   *
   * @param value: any
   * @param args: any
   */
  transform(value, args?: string): string {
    if (value) {
      value = Math.round(Number(value));
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return value;
  }
}
