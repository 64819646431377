import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { ButtonParams } from '@shared/models';
import { Client } from '@entities/client/client.model';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { AlertType } from '@shared/models/alert-type.enum';
import { ClientService } from '@entities/client/client.service';


@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss']
})
export class NewClientComponent extends SflBaseComponent implements OnInit {

  @Output() closeSidebarEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() updateTheCustomerList: EventEmitter<Client> = new EventEmitter();
  cardTitle = 'Create Client';
  cardSubTitle = null;
  buttons: ButtonParams[] = [
    {
      btnClass: 'btn-close-icon',
      btnIcon: 'times',
      action: this.onClose.bind(this)
    }
  ];
  createClientForm: FormGroup;
  constructor( private readonly layoutUtilsService: LayoutUtilsService, private readonly clientService: ClientService) {
    super();
  }

  initForm() {
    this.createClientForm = new FormGroup({
      'name': new FormControl('', Validators.required),
      'is_active': new FormControl(true),
      'comment': new FormControl(''),
      'is_primary': new FormControl(true)
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  onClose(client?: Client) {
    this.isSubmitting = false;
    this.closeSidebarEvent.next(true);
      this.updateTheCustomerList.next(client);
  }

  async onSubmit() {
    if(!this.checkFormForValidation(this.createClientForm)){
        if (!await this.isClientWithSameNameExists()) {
          this.addClient();
        } else {
          this.layoutUtilsService.showActionNotification('A client with the same name already exists.', AlertType.Error);
        }
    }
  }

  isClientWithSameNameExists(): Promise<boolean> {
    const queryString = {
      name: this.createClientForm.controls['name'].value
    }
    return new Promise(resolve => {
      this.subscriptionManager.add(this.clientService.getClientByName(queryString).subscribe(res => {
        resolve (res?.body?.data?.customers?.length > 0);
      }));
    })
  }

  addClient() {
      this.subscriptionManager.add(this.clientService.createClient(this.createClientForm.value).subscribe(res => {
          this.layoutUtilsService.showActionNotification('Client added successfully', AlertType.Success);
          if(this.createClientForm.value.is_primary){
            res.data.customer = {...res.data.customer,useNewClient:true}
          }
          this.onClose(res.data);
      }, () => {
        this.isSubmitting = false;
        this.layoutUtilsService.showActionNotification('There was an error while adding client', AlertType.Error);
      }));
    }
}
