import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-filter-table-fields",
  templateUrl: "./filter-table-fields.component.html",
})
export class FilterTableFieldsComponent {
  constructor() {}
  @Input() selectedColumns: any;
  @Input() frozenCols: any;
  @Input() dynamicBindingKey: string;
  @Input() columnChangeHold: any;
  @Output() onSelectColumChange = new EventEmitter<any>();

  onSelectColumsChange(event?: any) {
    this.onSelectColumChange.emit(event ? event : undefined);
  }
}
