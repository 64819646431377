import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterTag } from '../../models/custom/filter-tag';
@Component({
  selector: 'app-selected-filter-tags',
  templateUrl: './selected-filter-tags.component.html',
  styleUrls: ['./selected-filter-tags.component.scss']
})
export class SelectedFilterTagsComponent implements OnInit {
  @Input() _tags: FilterTag[];
  @Input() _taggingTags: string[];
  @Input() showClear: boolean = true;
  statusValues: string[];
  @Output() filterReset = new EventEmitter();
  @Output() saveFilter = new EventEmitter();
  @Input() set tags(value: FilterTag[]) {
    this._tags = value;
    if (this._tags.length) {
      this.statusValues = this._tags.filter(tag => tag.label === 'Project Status')[0]?.value?.split(',');
    }
  }

  @Output() onCancel = new EventEmitter();
  @Output() onClearSelectedTaggingTag = new EventEmitter();
  @Output() onRemoveStatus = new EventEmitter();
  constructor() {
  }


  removeStatus(status) {
    this.statusValues = this.statusValues.filter(value => value !== status);
    this.onRemoveStatus.emit(this.statusValues);
  }

  ngOnInit(): void {

  }

  _onCancel(tag) {
    this.onCancel.emit(tag);
  }

  _onClearAppliedTagCancel(clearThisTag) {
    this.onClearSelectedTaggingTag.emit(clearThisTag);
  }

  resetFilter() {
    this.filterReset.emit();
  }
  onSaveFilter() {
    this.saveFilter.emit();
  }

}
