import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CheckAuthResolver implements Resolve<any> {

  loader$ = new Subject<boolean>();

  constructor(private readonly authService: AuthService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.loader$.next(true);
    await this.authService.checkAuthSessionStatus().toPromise().catch(async err => {
      await this.authService.refreshToken().toPromise().catch(err =>{
        this.authService.logout();
        this.loader$.next(false);
        return true;
      });
      this.loader$.next(false);
      return true;
    });
    this.loader$.next(false);
    return true;
  }

}
