import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthNoticeService } from '@auth/auth-notice/auth-notice.service';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { AlertType } from '@shared/models/alert-type.enum';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { GlobalDetailSubCategory, GlobalDetailTaggingCategory, GlobalDetailTags, SubCategory, Tag, TagCategory } from '../administration.model';
import { AdministrationService } from '../administration.service';
import { PNGTree, TreeNode, TreeViewStructure } from './tree-view-model';
import { ProjectService } from '@entities/project/project.service';
import { ClientService } from '@entities/client/client.service';
import { UtilizationService } from '@entities/utilization-management/utilization.service';


interface ModalData {
  tags: string[],
  employeeId: number[],
  title: string,
  flag: string,
  positionId:number
}

@Component({
  selector: 'app-append-tags',
  templateUrl: './append-tags.component.html',
  styleUrls: ['./append-tags.component.scss']
})
export class AppendTagsComponent extends SflBaseComponent implements OnInit {

  viewLoading: boolean;
  loadingAfterSubmit: boolean;
  isSubmitting: boolean;
  tagsForm: FormGroup;
  categoryMasterData: TagCategory[] = [];
  subCategoryMasterData: SubCategory[] = [];
  groupedCategories: TagCategory[] = [];
  groupedCategories2: TreeViewStructure;
  subCategories: SubCategory[] = [];
  selectedCategoriesTag: PNGTree[] = [];
  tagSubCategory: SubCategory[] = [];
  tagCategories: TagCategory[] = [];
  loading = false;
  cardTitle = 'Add Tags';
  cardSubTitle = null;
  globalDetailsTaggingCategory: GlobalDetailTaggingCategory;
  globalDetailsTagSubCategory: GlobalDetailSubCategory;
  globalDetailsTag: GlobalDetailTags;
  tags: Tag[] = [];
  groupedCategory: TreeViewStructure;
  finalTagsAlongWithTheCategory: string[] = [];
  showDeleteDialog: boolean;
  deletingTagIndex: number;
  deletingTag: string;
  constructor(public readonly dialogRef: MatDialogRef<AppendTagsComponent>,
    @Inject(MAT_DIALOG_DATA) public appendDataCollection: ModalData,
    private readonly adminService: AdministrationService,
    private readonly authNoticeService: AuthNoticeService,
    private readonly layoutUtilsService: LayoutUtilsService,
    private readonly projectService: ProjectService,
    private readonly clientService: ClientService,
    private readonly utilizationService:UtilizationService) {
      super();
    }

  ngOnInit(): void {
    this.authNoticeService.setNotice(null);
    this.initTagsForm();
    this.getCategoryMasterData();
  }

  initTagsForm() {
    this.tagsForm = new FormGroup({
      selectedTags: new FormControl('')
    })
  }

  getCategoryMasterData() {
    this.tagCategories = [];
    this.loading = true;
    this.subscriptionManager.add(
      this.adminService.getTagCategories('TagCategoryManagement').subscribe((res) => {
        this.loading = false;
        if (res?.data?.global_details) {
          const globalDetail = res?.data?.global_details;
          if (globalDetail && globalDetail[0]?.global_detail?.name === 'TagCategoryManagement') {
            this.globalDetailsTaggingCategory = globalDetail[0];
            this.tagCategories = globalDetail[0].global_detail.extended_fields.tagCategory;
            this.adminService.setTagCategories(globalDetail[0].global_detail);
            this.getTagSubCategories();
          }
        }
      }, () => this.loading = false)
    )
  }

  getTagSubCategories() {
    this.tagSubCategory = [];
    this.loading = true;
    this.subscriptionManager.add(
      this.adminService.getTagSubCategories('SubCategoryManagement').subscribe((res) => {
        this.loading = false;
        if (res?.data?.global_details) {
          const globalDetail = res?.data?.global_details;
          if (globalDetail && globalDetail[0]?.global_detail?.name === 'SubCategoryManagement') {
            this.globalDetailsTagSubCategory = globalDetail[0];
            this.tagSubCategory = globalDetail[0].global_detail.extended_fields.subCategory;
            this.adminService.setTagSubCategories(globalDetail[0].global_detail);
            this.getGlobalDetailTags();
          }
        }
      }, () => this.loading = false)
    )
  }

  getGlobalDetailTags() {
    this.tags = [];
    this.loading = true;
    this.subscriptionManager.add(
      this.adminService.getTags('TagManagement').subscribe((res) => {
        this.loading = false;
        if (res?.data?.global_details) {
          const globalDetail = res?.data?.global_details;
          if (globalDetail && globalDetail[0]?.global_detail?.name === 'TagManagement') {
            this.globalDetailsTag = globalDetail[0];
            this.tags = globalDetail[0].global_detail.extended_fields.tags;
            this.adminService.setTags(globalDetail[0].global_detail);
            this.combineCategoryAndSubCategory();
          }
        }
      }, () => this.loading = false)
    )
  }

  combineCategoryAndSubCategory() {
    for (const category of this.globalDetailsTaggingCategory.global_detail.extended_fields.tagCategory) {
      category.subTagCategory = [...this.tagSubCategory?.filter(subCate => subCate?.parentCategoryId === category?.id)];
    }
    this.injectTagsToRespectiveCategoryOrSubCategory();
    this.initGroupingCategoryTags();
  }

  injectTagsToRespectiveCategoryOrSubCategory() {
    for (const category of this.globalDetailsTaggingCategory.global_detail.extended_fields.tagCategory) {
      for (const tag of this.tags) {
        if( tag.tagCategory === category.id) {
          const subCateIndex = category.subTagCategory.findIndex(subCate=> subCate.id === tag.subTagCategory);
          if (subCateIndex !== -1) {
            category.subTagCategory[subCateIndex]['tags'].push(tag);
          } else {
            category.tags.push(tag);
          }
        }
      }
      category.subTagCategory = [...this.tagSubCategory?.filter(subCate => subCate?.parentCategoryId === category?.id)];
    }
  }

  initGroupingCategoryTags() {
    this.groupedCategory = {data: []};
    for (const [index, category] of this.globalDetailsTaggingCategory.global_detail.extended_fields.tagCategory.entries()) {
      let dataCollection: TreeNode = new TreeNode();
      dataCollection.label = category.name;
      dataCollection.selectable = false;
      dataCollection.collapsedIcon = "pi-chevron-right"
      dataCollection.expandedIcon = "pi-chevron-down"
      if (category?.subTagCategory?.length) {
        for (const [subIndex, subCate] of category?.subTagCategory?.entries()) {
          dataCollection.children.push({label: subCate.name, collapsedIcon: "pi-chevron-right", expandedIcon: "pi-chevron-down", children: [], selectable: false, expanded: true});
          if (subCate?.tags?.length) {
            for (const [tagIndex, tag] of subCate?.tags?.entries()) {
              dataCollection.children[subIndex]?.children?.push({label: tag.name, collapsedIcon: "pi-chevron-right", expandedIcon: "pi-chevron-down", expanded: true});
            }
          }
        }
      }
      if (category?.tags?.length) {
        for (const [tagIndex, tag] of category?.tags?.entries()) {
          dataCollection.children?.push({label: tag.name, collapsedIcon: "pi-chevron-right", expandedIcon: "pi-chevron-down", expanded: true});
        }
      }
      this.groupedCategory.data.push(dataCollection);
    }

  }

  setCategoryGroup(category): TreeNode {
    let node: TreeNode;
    return node;
  }

  setSubCategory(tags) {

  }

  setTags() {

  }

  saveTags() {
    const categoryAndTags = this.tagsForm.get('selectedTags').value;
    this.selectedCategoriesTag = categoryAndTags.filter(tag => !(tag.hasOwnProperty('selectable')) );
    for (const selectedTags of this.selectedCategoriesTag) {
      let labelHolder = '';
      labelHolder += selectedTags.label
      if (selectedTags.parent) {
        labelHolder = selectedTags.parent.label+'__'+labelHolder
        if (selectedTags.parent?.parent) {
          labelHolder = selectedTags.parent?.parent?.label+'__'+labelHolder
          if (selectedTags.parent?.parent?.parent) {
            labelHolder = selectedTags.parent?.parent?.parent?.label+'__'+labelHolder
          }
        }
      }
      this.finalTagsAlongWithTheCategory.push(labelHolder);
    }
    // append existing tag to the payload, remove if duplicate found
    for (const tag of this.appendDataCollection.tags) {
      if(!this.finalTagsAlongWithTheCategory.includes(tag))
        this.finalTagsAlongWithTheCategory.push(tag);
    }
    // check which tags are we trying to add, we will be using pre defined flags here e.g. EMPLOYEE_TAGS, CLIENT_TAGS, etc based on the flag we will call the api
    switch (this.appendDataCollection.flag) {
      case 'EMPLOYEE_TAG':
        this.subscriptionManager.add(this.adminService.appendTagToAnEmployee(String(this.appendDataCollection.employeeId), this.finalTagsAlongWithTheCategory).subscribe(res => {
          this.onSuccess();
        }))
        break;
      case 'CLIENT_TAG':
        this.subscriptionManager.add(this.adminService.appendTagToACleint(String(this.appendDataCollection.employeeId), this.finalTagsAlongWithTheCategory).subscribe(res => {
          this.onSuccess();
        }))
        break;
        case 'PROJECT_TAG':
          this.subscriptionManager.add(this.adminService.appendTagToAProject(String(this.appendDataCollection.employeeId), this.finalTagsAlongWithTheCategory).subscribe(res => {
            this.onSuccess();
          }))
        break;
        case 'ADD TAGS TO CHECKED PROJECT':
          for (const employeeId of this.appendDataCollection.employeeId) {
            this.subscriptionManager.add(this.adminService.appendTagToAProject(String(employeeId), this.finalTagsAlongWithTheCategory).subscribe((res)=>{
              if (this.appendDataCollection.employeeId[this.appendDataCollection.employeeId.length-1] === employeeId) { 
                this.projectService.showNewTags.next(true);
                this.onSuccess();
              }
            }))
          }
        break;
      case 'ADD TAGS TO CHECKED CLIENT':
        for (const clientId of this.appendDataCollection.employeeId) {
          this.subscriptionManager.add(this.adminService.appendTagToACleint(String(clientId), this.finalTagsAlongWithTheCategory).subscribe((res) => {
            if (this.appendDataCollection.employeeId[this.appendDataCollection.employeeId.length-1] === clientId) { 
              this.clientService.showNewTags.next(true);
              this.onSuccess();
            }
          }))
        }
      case 'ADD TAGS TO CHECKED EMPLOYEE':
        for (const employeeId of this.appendDataCollection.employeeId) {
          this.subscriptionManager.add(this.adminService.appendTagToAnEmployee(String(employeeId), this.finalTagsAlongWithTheCategory).subscribe((res) => {
            if (this.appendDataCollection.employeeId[this.appendDataCollection.employeeId.length - 1] === employeeId) {
              this.adminService.showNewAddedTags.next(true);
              this.onSuccess();
            }
          }))
        }
      case 'ADD TAGS TO POSITION':
        this.subscriptionManager.add(this.adminService.appendTagToAPosition(String(this.appendDataCollection.positionId), this.finalTagsAlongWithTheCategory).subscribe(res => {
          this.utilizationService.showTagsForEditPosition.next(true);
            this.onSuccess();
        }))
    }
  }

  onSuccess() {
    this.layoutUtilsService.showActionNotification('Tag added successfully', AlertType.Success);
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close();
  }

  getTag(tag: string) {
    return tag.split('__').pop();
  }


  getTagCategorySubCategory(tag: string): string {
    const tagArray = tag.split('__');
    const categoryName = tagArray[0];
    const subCategory = tagArray?.length > 2 ? tagArray[1] : null;
    if (subCategory)
      return `Category <strong>${categoryName}</strong> <br> Sub Category<strong>${subCategory}</strong>`;
    else
      return `Category <strong>${categoryName}</strong>`;
  }

  toggleWithCategory(tooltip, tag) {
    if (tooltip.isOpen()) {
      tooltip.close();
    } else {
      tooltip.open({ tag });
    }
  }

  initiateRemoveTag(tag: string, tooltip, index: number) {
    this.showDeleteDialog = true;
    this.deletingTag = tag;
    this.deletingTagIndex = index;
    tooltip.close();
  }

  deleteTag() {
    switch (this.appendDataCollection.flag) {
      case 'EMPLOYEE_TAG':
        this.subscriptionManager.add(this.adminService.deleteTagFromAnEmployee(this.appendDataCollection.employeeId.toString(), [this.deletingTag]).subscribe(res => {
          this.layoutUtilsService.showActionNotification('Tag has been removed successfully', AlertType.Success);
          this.appendDataCollection.tags.splice(this.deletingTagIndex,1);
          this.showDeleteDialog = false;
        }))
        break;
      case 'CLIENT_TAG':
        this.subscriptionManager.add(this.adminService.deleteTagFromACleint(this.appendDataCollection.employeeId.toString(), [this.deletingTag]).subscribe(res => {
          this.layoutUtilsService.showActionNotification('Tag has been removed successfully', AlertType.Success);
          this.appendDataCollection.tags.splice(this.deletingTagIndex,1);
          this.showDeleteDialog = false;
        }))
        break;
        case 'PROJECT_TAG':
          this.subscriptionManager.add(this.adminService.deleteTagFromAProject(this.appendDataCollection.employeeId.toString(), [this.deletingTag]).subscribe(res => {
            this.layoutUtilsService.showActionNotification('Tag has been removed successfully', AlertType.Success);
            this.appendDataCollection.tags.splice(this.deletingTagIndex,1);
            this.showDeleteDialog = false;
          }))
        break;
        case 'ADD TAGS TO POSITION':
          this.subscriptionManager.add(this.adminService.deleteTagFromAPosition(this.appendDataCollection.positionId.toString(), [this.deletingTag]).subscribe(res => {
            this.layoutUtilsService.showActionNotification('Tag has been removed successfully', AlertType.Success);
            this.appendDataCollection.tags.splice(this.deletingTagIndex, 1);
            this.showDeleteDialog = false;
          }))
        break;
    }
  }

  closeModal() {
    this.appendDataCollection.tags.splice(this.deletingTagIndex,0,this.deletingTag);
    this.deletingTag = null;
    this.showDeleteDialog = false;
  }
}
