<div class="card card-custom add-contact">
  <app-card-header [cardTitle]="cardTitle" [cardSubTitle]="cardSubTitle" [buttons]="buttons" [cardLabelClass]="'mb-0'"></app-card-header>
  <div class="card-body create-card">
    <form class="form" [formGroup]="createClientForm" autocomplete="off" novalidate="novalidate" id="createContactForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-label">Client Name</label>
            <input class="form-control custom" required placeholder="Client Name" formControlName="name" minlength="5" maxlength="255">
            <app-form-error [validation]="'required'" [form]="createClientForm" [controlName]="'name'" [fieldLabel]="'Client Name'"></app-form-error>
          </div>
          <div class="form-group">
            <label class="form-label">Status</label>
            <span class="status-toggle">Inactive <mat-slide-toggle class="mx-3" color="warn" formControlName="is_active"></mat-slide-toggle>Active</span>
          </div>
          <div class="form-group">
            <label class="form-label">Comments</label>
            <textarea class="form-control custom" placeholder="Type comment here" rows="5" formControlName="comment"></textarea>
          </div>
          <div class="form-group">
            <input type="checkbox" formControlName="is_primary" id="isPrimary" class="form-check" />
            <label for="isPrimary" class="label-form-check">Use this Client for Current Project</label>
          </div>
        </div>
      </div>
      <div class="form-group d-flex flex-wrap justify-content-end align-items-center mt-2">
        <button id="addContactCancel" type="button" class="btn-cancel" (click)="onClose()">Cancel</button>
        <button id="addContactSubmit" type="submit" [isSubmitting]="isSubmitting" class="btn-save">Save</button>
      </div>
    </form>
  </div>
</div>
