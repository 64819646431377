import {
  Directive,
  ElementRef,
  HostListener,
  Input
} from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';


@Directive({
  selector: '[sflIsNumber]'
})
export class IsNumberDirective {
  private readonly el: HTMLInputElement;
  constructor(private readonly elementRef: ElementRef) {
    this.elementRef.nativeElement.onkeypress = event => {
      if (event.which < 48 || event.which > 57) {
        event.preventDefault();
      }
    };
  }
}

@Directive({
  selector: '[sflIsDecimalNumber]'
})
export class IsDecimalNumberDirective {
  @Input('decimals') decimals = 0;
  private check(value: string, decimals) {
    if (decimals <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    } else {
      var regExpString = "^\\s*((\\d+(\\.\\d{0," + decimals + "})?)|((\\d*(\\.\\d{1," + decimals + "}))))\\s*$"
      return String(value).match(new RegExp(regExpString));
    }
  }
  private specialKeys = [
    'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
  ];

  constructor(readonly el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1 || isNaN(this.el.nativeElement.value)) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !this.check(next, this.decimals) && isNaN(this.el.nativeElement.value)) {
      event.preventDefault();
    }
  }
}

export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = nameRe.test(control.value);
    return forbidden ? { forbiddenName: { value: control.value } } : null;
  };
}
