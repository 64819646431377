import * as CryptoJS from 'crypto-js';
import { environment } from '@environment/environment';

export class Utility {
  private static readonly secretKey = environment.sentryKey;

  public static Encrypt(value): string {
    return value ? CryptoJS.AES.encrypt(value.toString(), this.secretKey.trim()).toString() : null;
  }

  public static Decrypt(value: string): string {
    return CryptoJS.AES.decrypt(value, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
  }

  /**
   * generates unique uuid
   * generate groups of 4 random characters
   * @example getUniqueId(1) : 607f
   * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
 */
  public static getUniqueId(parts: number): string {
    const stringArr = [];
    for(let i = 0; i< parts; i++){
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }
}
