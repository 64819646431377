import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { ApiUrl } from "@shared/constants";
import { OGantryHttpResponse } from "@shared/models";
import {
  EmployeeTypesList,
  GlobalDetailList,
  EmployeeWiseData,
  Ids,
  OpenPositionReportList,
  ProjectList,
  RegionList,
  EmployeeData,
  PNLPlugs,
  PNLPlug,
} from "./utilization.model";
import * as FileSaver from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Position,
  PositionList,
  Project,
} from "@entities/project/project.model";
import {
  ISavedFilter,
  PositionTypeList,
} from "@entities/administration/administration.model";
import { ClientList } from "@entities/client/client.model";
import { Params } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class UtilizationService {
  showNewSharedFilter = new Subject<string>();
  showTagsForEditPosition = new Subject<boolean>();

  constructor(private readonly http: HttpClient) {}

  getBenchReport(
    queryFilter: any
  ): Observable<HttpResponse<OGantryHttpResponse<EmployeeWiseData>>> {
    return this.http.get<OGantryHttpResponse<EmployeeWiseData>>(
      ApiUrl.benchReport,
      { params: queryFilter, observe: "response" }
    );
  }

  getOpenPositionReport(
    queryFilter: any
  ): Observable<HttpResponse<OGantryHttpResponse<OpenPositionReportList>>> {
    return this.http.get<OGantryHttpResponse<OpenPositionReportList>>(
      ApiUrl.openPositionReport,
      { params: queryFilter, observe: "response" }
    );
  }

  getEmployeeTypes(): Observable<OGantryHttpResponse<EmployeeTypesList>> {
    return this.http.get<OGantryHttpResponse<EmployeeTypesList>>(
      ApiUrl.employeeTypes
    );
  }

  getRegions(): Observable<OGantryHttpResponse<RegionList>> {
    return this.http.get<OGantryHttpResponse<RegionList>>(ApiUrl.regions);
  }

  getGlobalDetails(
    detailName
  ): Observable<OGantryHttpResponse<GlobalDetailList>> {
    return this.http.get<OGantryHttpResponse<GlobalDetailList>>(
      ApiUrl.globalDetails,
      { params: { name: detailName } }
    );
  }

  getGroup(requestObject) {
    return this.http.get(ApiUrl.saveFilter, { params: requestObject });
  }

  getPositionList(): Observable<OGantryHttpResponse<PositionList>> {
    return this.http.get<OGantryHttpResponse<PositionList>>(
      `${ApiUrl.position}`,
      { params: { visibility: "Public" } }
    );
  }

  getProjectList(): Observable<OGantryHttpResponse<ProjectList>> {
    return this.http.get<OGantryHttpResponse<ProjectList>>(ApiUrl.project);
  }

  getEmployeeIds(name: string): Observable<Ids> {
    return this.http.get<Ids>(`${ApiUrl.employee}/ids?${name}`);
  }

  getClientIds(name: string): Observable<Ids> {
    return this.http.get<Ids>(`${ApiUrl.client}/ids?${name}`);
  }

  getProjectIds(name: string): Observable<Ids> {
    return this.http.get<Ids>(`${ApiUrl.project}/ids?${name}`);
  }

  exportPdf(col: any[], data: any[], filename: string, no: number) {
    const doc = new jsPDF("l", "pt", "", true);
    const colLength: number = col.length;
    doc["autoTable"](col, data, {
      startY: 40,
      margin: 0,
      tableWidth: "screenwidth",
      cellWidth: "screenwidth",
      showHead: "everyPage",
      tableLineColor: 200,
      tableLineWidth: 0,
      didParseCell: function (table) {
        if (
          table.section === "body" &&
          (table?.cell?.raw?.includes("%") ||
            table?.cell?.raw?.includes("$") ||
            table?.cell?.raw?.includes("--") ||
            table.column.dataKey.startsWith("Hours In"))
        ) {
          table.cell.styles.halign = "right";
        }
        if (table.section === "head") {
          for (let i = no; i < colLength; i++) {
            if (table.column.index === i) {
              table.cell.styles.halign = "right";
            }
          }
        }
        if (colLength > 6) {
          table.cell.styles.fontSize = 5;
        }
      },
      styles: {
        overflowX: "scroll",
        overflow: "linebreak",
        cellWidth: "wrap",
        fontSize: 10,
        cellPadding: 2,
        overflowColumns: "linebreak",
      },
    });
    doc.save(`${filename}.pdf`);
  }
  exportPdfManageTimesheet(col: any[], data: any[], filename: string, no: number) {
    const doc = new jsPDF("l", "mm", [350, 500], true);
    const colLength: number = col.length;
    doc["autoTable"](col, data, {
      startY: 40,
      margin: 0,
      tableWidth: "screenwidth",
      cellWidth: "screenwidth",
      showHead: "everyPage",
      tableLineColor: 200,
      tableLineWidth: 0,
      didParseCell: function (table) {
        if (
          table.section === "body" &&
          (table?.cell?.raw?.toString().includes("%") ||
            table?.cell?.raw?.toString().includes("$") ||
            table?.cell?.raw?.toString().includes("--") ||
            table.column.dataKey.toString().startsWith("Hours In"))
        ) {
          table.cell.styles.halign = "right";
        }
        if (table.section === "head") {
          for (let i = no; i < colLength; i++) {
            if (table.column.index === i) {
              table.cell.styles.halign = "right";
            }
          }
        }
        if (colLength > 6) {
          table.cell.styles.fontSize = 5;
        }
      },
      styles: {
        overflowX: "scroll",
        overflow: "linebreak",
        cellWidth: "wrap",
        fontSize: 10,
        cellPadding: 2,
        overflowColumns: "linebreak",
      },
    });
    doc.save(`${filename}.pdf`);
  }

  exportToCsv(rows: object[], fileName: string, columns?: string[]): string {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ",";
    const keys = [];
    columns.forEach((col) => {
      if (rows[0][col] !== undefined) {
        keys.push(col);
      }
    });
    const csvContent =
      keys.join(separator) +
      "\n" +
      rows
        .map((row) => {
          return keys
            .map((k) => {
              let cell = row[k] === null || row[k] === undefined ? "" : row[k];
              cell =
                cell instanceof Date
                  ? cell.toLocaleString()
                  : cell.toString().replace(/"/g, '""');
              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
              }
              return cell;
            })
            .join(separator);
        })
        .join("\n");
    this.saveAsFile(csvContent, `${fileName}.csv`, "text/plain;charset=utf-8");
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  }

  exportExcel(headers, reportData, filename, originalExportReportData = []) {
    import("xlsx").then((xlsx) => {
      if (originalExportReportData.length) {
        const data1 = [...headers, ...originalExportReportData];
        const worksheet1 = xlsx.utils.json_to_sheet(data1, {
          skipHeader: true,
        });
        const data2 = [...headers, ...reportData];
        const worksheet2 = xlsx.utils.json_to_sheet(data2, {
          skipHeader: true,
        });
        const workbook = {
          Sheets: {
            "Hierarchical View": worksheet1,
            "Filter View": worksheet2,
          },
          SheetNames: ["Hierarchical View", "Filter View"],
        };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        this.saveAsExcelFile(excelBuffer, filename);
      } else {
        const data = [...headers, ...reportData];
        const worksheet = xlsx.utils.json_to_sheet(data, { skipHeader: true });
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        this.saveAsExcelFile(excelBuffer, filename);
      }
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then((FileSaver) => {
      const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const EXCEL_EXTENSION = ".xlsx";
      this.saveAsFile(
        buffer,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION,
        EXCEL_TYPE
      );
    });
  }

  getEmployee(id): Observable<OGantryHttpResponse<EmployeeData>> {
    return this.http.get<OGantryHttpResponse<EmployeeData>>(
      `${ApiUrl.employee}/${id}`
    );
  }

  getProject(id): Observable<OGantryHttpResponse<Project>> {
    return this.http.get<OGantryHttpResponse<Project>>(
      `${ApiUrl.project}/${id}`
    );
  }

  getPositionTypes(): Observable<OGantryHttpResponse<PositionTypeList>> {
    return this.http.get<OGantryHttpResponse<PositionTypeList>>(
      `${ApiUrl.positionType}`
    );
  }
  createFilter(requestObject): Observable<ISavedFilter> {
    return this.http.post<ISavedFilter>(ApiUrl.saveFilter, {
      query_filter: requestObject,
    });
  }
  updateFilter(id, requestObject): Observable<ISavedFilter> {
    return this.http.put<ISavedFilter>(`${ApiUrl.saveFilter}/${id}`, {
      query_filter: requestObject.query_filter,
    });
  }

  deleteStoredFilters(id) {
    return this.http.delete(`${ApiUrl.saveFilter}/${id}`);
  }
  getStoredFilters(requestObject) {
    return this.http.get(ApiUrl.saveFilter, { params: requestObject });
  }
  getActiveEmployees(
    id,
    params
  ): Observable<HttpResponse<OGantryHttpResponse<PositionList>>> {
    return this.http.get<OGantryHttpResponse<PositionList>>(
      `${ApiUrl.activeEmployeePosition}/${id}`,
      { params: params, observe: "response" }
    );
  }
  getClientData(): Observable<HttpResponse<OGantryHttpResponse<ClientList>>> {
    return this.http.get<OGantryHttpResponse<ClientList>>(ApiUrl.client, {
      params: { order_by: "asc:name" },
      observe: "response",
    });
  }
  createPNLPlug(plugObj): Observable<OGantryHttpResponse<PNLPlug>> {
    return this.http.post<OGantryHttpResponse<PNLPlug>>(ApiUrl.pnlPlug, {
      pnl_plug: plugObj,
    });
  }
  updatePNLPlug(plugObj, id): Observable<OGantryHttpResponse<PNLPlug>> {
    return this.http.put<OGantryHttpResponse<PNLPlug>>(
      `${ApiUrl.pnlPlug}/${id}`,
      { pnl_plug: plugObj }
    );
  }
  getPNLPlugList(
    params
  ): Observable<HttpResponse<OGantryHttpResponse<PNLPlugs>>> {
    return this.http.get<OGantryHttpResponse<PNLPlugs>>(ApiUrl.pnlPlug, {
      params: params,
      observe: "response",
    });
  }

  updatePosition(positionObject): Observable<OGantryHttpResponse<Position>> {
    if (positionObject?.id) {
      return this.http.put<OGantryHttpResponse<Position>>(
        `${ApiUrl.position}/${positionObject.id}`,
        { position: positionObject }
      );
    }
    return this.http.post<OGantryHttpResponse<Position>>(`${ApiUrl.position}`, {
      position: positionObject,
    });
  }

  getPositionById(
    id: number,
    params: HttpParams
  ): Observable<OGantryHttpResponse<any>> {
    const url = `${ApiUrl.timeEntriesByPostion}/${id}`;
    return this.http.get<OGantryHttpResponse<any>>(url, { params });
  }
}
