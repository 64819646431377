<div class="badges mb-2 ml-4 mt-2 mr-4" *ngIf="_tags?.length > 0">
  <span class="apply-filter">Applied Filters:</span>
  <!-- Button for Reset Filter -->
  <!-- <span class="ml-1" id="resetFilter" *ngIf="_tags[0]?.isResertButtonShown">
    <a title="Reset Filter" (click)="resetFilter()" class="btn btn-icon btn-icon-light btn-sm icon-background mr-2">
      <span [inlineSVG]="'assets/media/svg/icons/clear-filter.svg'" cacheSVG="true" class="svg-icon svg-icon-md">
      </span>
    </a>
  </span> -->
  <span class="p-tag" *ngFor="let tag of _tags">
    <span *ngIf="tag.label === 'Project Status'; else otherTags">
      {{ tag?.label }}:
      <mat-chip-list
        aria-label="Fish selection"
        *ngFor="let item of statusValues"
      >
        <mat-chip class="chip" [removable]="true" (removed)="removeStatus(item)"
          >{{ item }} <mat-icon matChipRemove>clear</mat-icon></mat-chip
        >
      </mat-chip-list>
    </span>
    <ng-template #otherTags>
      <span>{{ tag?.label }}: {{ tag?.value }}</span>
      <button *ngIf="showClear" class="btn-close-icon" (click)="_onCancel(tag)">
        <mat-icon>clear</mat-icon>
      </button>
    </ng-template>
  </span>
  <span class="apply-filter" *ngIf="_taggingTags?.length">Applied Tags:</span>
  <span class="p-tag" *ngFor="let appliedTags of _taggingTags">
    <span>{{ appliedTags?.label ?? appliedTags }}</span>
    <button
      *ngIf="showClear"
      class="btn-close-icon"
      (click)="_onClearAppliedTagCancel(appliedTags)"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </span>
  <!-- buttons for save and reset Filter -->
  <!-- <span class="" id="saveFilter" *ngIf="!_tags[0]?.isResertButtonShown">
    <a title="Save Filter" (click)="onSaveFilter()" class="btn btn-icon btn-icon-light btn-sm icon-background">
      <span [inlineSVG]="'assets/media/svg/icons/save.svg'" cacheSVG="true" class="svg-icon svg-icon-md">
      </span>
    </a>
  </span>
  <span class="ml-1" id="resetFilter" *ngIf="!_tags[0]?.isResertButtonShown">
    <a title="Reset Filter" (click)="resetFilter()" class="btn btn-icon btn-icon-light btn-sm icon-background">
      <span [inlineSVG]="'assets/media/svg/icons/clear-filter.svg'" cacheSVG="true" class="svg-icon svg-icon-md">
      </span>
    </a>
  </span> -->
</div>
