<!-- begin:: Footer -->
<div class="footer bg-white py-2 d-flex flex-lg-column" id="kt_footer" [ngClass]="footerClasses">
  <div [ngClass]="footerContainerClasses" class="d-flex flex-column flex-md-row align-items-center justify-content-between">
    <!--begin::Copyright-->
    <div class="text-dark order-2 order-md-1">
      <span class="text-muted font-weight-bold mr-2"> &copy;</span>
      <a href="https://www.ogantry.com/" target="_blank" class="text-dark-75 text-hover-primary" rel="noopener noreferrer">OGantry </a>
      <span class="text-muted font-weight-bold mr-2">{{ today | date: "yyyy" }} </span>
    </div>
    <!--end::Copyright-->

  </div>
</div>
<!-- end:: Footer -->
