// Angular
import { Component, Inject, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
// RxJS
import { delay } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'kt-action-natification',
  templateUrl: './action-notification.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class ActionNotificationComponent implements OnInit {
	/**
	 * Component constructor
	 *
	 * @param data: any
	 */
  constructor(@Inject(MAT_SNACK_BAR_DATA) public readonly data: any) { }
	/**
	 * On init
	 */
  ngOnInit() {
    if (!this.data.showUndoButton || (this.data.undoButtonDuration >= this.data.duration)) {
      return;
    }

    this.delayForUndoButton(this.data.undoButtonDuration).subscribe(() => {
      this.data.showUndoButton = false;
    });
  }

	/*
	 *	Returns delay
	 *
	 * @param timeToDelay: any
	 */
  delayForUndoButton(timeToDelay) {
    return of('').pipe(delay(timeToDelay));
  }

	/**
	 * Dismiss with Action
	 */
  onDismissWithAction() {
    this.data.snackBar.dismiss();
  }

	/**
	 * Dismiss
	 */
  public onDismiss() {
    this.data.snackBar.dismiss();
  }
}
