<div class="tags-listing-view-wrapper pb-8">
  <div class="header h-100">
    <div class="title-head pl-6">
      <h5 *ngIf="_flag !== 'PROJECT_TAG'">Tags</h5>
      <h5 class="text-center cursor-pointer" (click)="openAddNewTagsDialog()"><span>Add +</span></h5>
    </div>
  </div>
  <div class="tag-listing">
    <p-chip *ngFor="let tag of _tags;  let i=index" class="pr-2 pb-2 tag-list-tags" [ngbTooltip]="categoryDetails" #t2="ngbTooltip" (mouseenter)="toggleWithCategory(t2, tag)" [label]="getTag(tag)" [removable]="true" (onRemove)="initiateRemoveTag(tag, t2, i)"></p-chip>
  </div>
</div>

<ng-template #categoryDetails let-tag="tag">
  <p [innerHTML]="getTagCategorySubCategory(tag)"></p>
</ng-template>

<p-dialog header="Delete" [(visible)]="showDeleteDialog" [modal]="true" class="confirm-dialog" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
  <h5 class="p-m-0 delete-title">Do you want to delete this tag?</h5>
  <ng-template pTemplate="footer">
    <div class="d-flex flex-wrap justify-content-end align-items-center">
      <button type="button" class="btn-cancel" (click)="closeModal()">No</button>
      <button type="button" class="btn-save" (click)="deleteTag()" [isSubmitting]="isSubmitting">Yes</button>
    </div>
  </ng-template>
</p-dialog>
