<!-- begin:: Header Topbar -->
<ng-container>
  <ng-container *ngIf="languagesDisplay">
    <!--begin: Language bar -->
    <kt-language-selector></kt-language-selector>
    <!--end: Language bar -->
  </ng-container>
  <ng-container *ngIf="userDisplay">
    <ng-container *ngIf="userLayout === 'dropdown'">
      <kt-user-profile [userDropdownStyle]="userDropdownStyle"></kt-user-profile>
    </ng-container>
  </ng-container>
</ng-container>
<!-- end:: Header Topbar -->
