<div class="container">
  <div>
    <h3 class="text-center mb-4">
      <fa-icon [icon]="'exclamation-triangle'"></fa-icon> Access Denied
    </h3>
    <h5>You tried to access a page you did not have prior authorization for.</h5>
    <div class="mt-4 text-center">
      <button (click)="goBack()" class="btn btn-primary">Go Back</button>
    </div>
  </div>
</div>
