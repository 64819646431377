<div class="contact-person-list" [ngClass]="{'row' : projectContact}">
  <div class="contact-person mb-5 col-5" [ngClass]="{'offset-md-2' : (projectContact && i%2!=0 && i!==0),'col-5':projectContact}" *ngFor="let contactPerson of contactPersons;let i=index">
    <div class="contact-info">
      <div><span class="person-name"><strong>{{contactPerson?.contact?.name}}</strong></span> <span>{{contactPerson?.contact?.title}}</span></div>
      <div class="contact">
        <span *ngIf="contactPerson?.contact?.phone"><span class="flaticon2-phone mr-2"></span>{{contactPerson?.contact?.phone | phoneMask}}</span>
        <span *ngIf="contactPerson?.contact?.email"> <span class="flaticon2-send mr-2 ml-4"></span>{{contactPerson?.contact?.email}}</span>
      </div>
    </div>
    <div class="del-icon">
      <span class="primary-contact">{{contactPerson?.contact?.is_primary ? 'PRIMARY' : ''}}</span>
      <a href="javascript:;void" (click)="openEditSideNav(contactPerson)" title="Update Contact Person" class="btn btn-icon btn-light btn-icon-light btn-hover-primary btn-sm">
        <span title="Update Contact Person" [inlineSVG]="'assets/media/svg/icons/edit.svg'" cacheSVG="true" class="svg-icon svg-icon-md">
        </span>
      </a>
      <a href="javascript:;void" (click)="showDeleteConfirmModal(contactPerson)" title="Delete Contact Person"
        class="btn btn-icon btn-light btn-icon-light btn-hover-primary btn-sm">
        <span title="Delete Contact Person" [inlineSVG]="'assets/media/svg/icons/delete.svg'" cacheSVG="true" class="svg-icon svg-icon-md">
        </span>
      </a>
    </div>
  </div>
</div>
