import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { ApiUrl } from '@shared/constants';
import { OGantryHttpResponse } from '@shared/models';
import { Employee, EmployeeList, WorkExceptionList, WorkExceptionTypes, GlobalDetail, GlobalDetailList, HolidayWorkException, NormalWorkException, PositionTypeList, PositionType, WorkExceptionType, JobList, GlobalDetailTagCategoryList, GlobalDetailTaggingCategory, GlobalDetailTags, GlobalDetailSubCategory, GlobalDetailSubCategoryList, EmployeeIds, EmployeeFinancial, GlobalDetailExtendedFiledListResponse } from './administration.model';
import { DailyExpenseType, DailyExpenseTypeList, MonthlyExpenseType, MonthlyExpenseTypeList } from '@entities/project/project.model';
import { EmployeeType, EmployeeTypesList, Ids } from '@entities/utilization-management/utilization.model';
@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  public roles: any;
  public tagCategories: any;
  public subCategories: any;
  public tags: any;
  public extended_Filed: any;
  showNewAddedTags = new Subject<boolean>();

  constructor(private readonly http: HttpClient) { }

  getEmployeeData(queryFilter?: any): Observable<HttpResponse<OGantryHttpResponse<EmployeeList>>> {
    return this.http.get<OGantryHttpResponse<EmployeeList>>(ApiUrl.employee, { params: queryFilter, observe: 'response' });
  }

  getEmployee(id: number): Observable<OGantryHttpResponse<Employee>> {
    return this.http.get<OGantryHttpResponse<Employee>>(`${ApiUrl.employee}/${id}`);
  }
  createEmployee(employeeData): Observable<OGantryHttpResponse<Employee>> {
    return this.http.post<OGantryHttpResponse<Employee>>(ApiUrl.employee, { employee: employeeData });
  }
  updateEmployee(employeeData, id): Observable<OGantryHttpResponse<Employee>> {
    return this.http.put<OGantryHttpResponse<Employee>>(`${ApiUrl.employee}/${id}`, { employee: employeeData });
  }
  terminateEmployee(employeeData, id): Observable<OGantryHttpResponse<Employee>> {
    return this.http.put<OGantryHttpResponse<Employee>>(`${ApiUrl.employee}/${id}/terminate`, { employee: employeeData });
  }
  updateEmployeeCost(employeeData, id): Observable<OGantryHttpResponse<Employee>> {
    return this.http.put<OGantryHttpResponse<Employee>>(`${ApiUrl.employee}/${id}/update_hourly_cost`, { employee: employeeData });
  }
  updateEmployeeUtilization(employeeData, id) {
    return this.http.put<OGantryHttpResponse<Employee>>(`${ApiUrl.employee}/${id}/update_utilization_target`, { employee: employeeData });
  }
  getRoles(detailName): Observable<OGantryHttpResponse<GlobalDetailList>> {
    return this.http.get<OGantryHttpResponse<GlobalDetailList>>(ApiUrl.globalDetails, { params: { name: detailName } });
  }

  getTagCategories(detailName: string): Observable<OGantryHttpResponse<GlobalDetailTagCategoryList>> {
    return this.http.get<OGantryHttpResponse<GlobalDetailTagCategoryList>>(ApiUrl.globalDetails, { params: { name: detailName } });
  }
  getExtendedFields(detailName: string): Observable<OGantryHttpResponse<GlobalDetailExtendedFiledListResponse>> {
    return this.http.get<OGantryHttpResponse<GlobalDetailExtendedFiledListResponse>>(ApiUrl.globalDetails, { params: { name: detailName } });
  }

  getTagSubCategories(detailName: string): Observable<OGantryHttpResponse<GlobalDetailSubCategoryList>> {
    return this.http.get<OGantryHttpResponse<GlobalDetailSubCategoryList>>(ApiUrl.globalDetails, { params: { name: detailName } });
  }

  getTags(detailName: string): Observable<OGantryHttpResponse<GlobalDetailTags>> {
    return this.http.get<OGantryHttpResponse<GlobalDetailTags>>(ApiUrl.globalDetails, { params: { name: detailName } });
  }

  updateRoles(roleData, id): Observable<OGantryHttpResponse<GlobalDetail>> {
    return this.http.put<OGantryHttpResponse<GlobalDetail>>(`${ApiUrl.globalDetails}/${id}`, { global_detail: roleData });
  }

  updateCategory(categoryData, id): Observable<OGantryHttpResponse<GlobalDetailTaggingCategory>> {
    return this.http.put<OGantryHttpResponse<GlobalDetailTaggingCategory>>(`${ApiUrl.globalDetails}/${id}`, { global_detail: categoryData });
  }

  updateSubCategory(categoryData, id): Observable<OGantryHttpResponse<GlobalDetailTaggingCategory>> {
    return this.http.put<OGantryHttpResponse<GlobalDetailTaggingCategory>>(`${ApiUrl.globalDetails}/${id}`, { global_detail: categoryData });
  }

  updateTag(tagData, id): Observable<OGantryHttpResponse<GlobalDetailTags>> {
    return this.http.put<OGantryHttpResponse<GlobalDetailTags>>(`${ApiUrl.globalDetails}/${id}`, { global_detail: tagData });
  }
  updateExtendedFiled(tagData, id): Observable<OGantryHttpResponse<GlobalDetailTags>> {
    return this.http.put<OGantryHttpResponse<GlobalDetailTags>>(`${ApiUrl.globalDetails}/${id}`, { global_detail: tagData });
  }

  addRoles(roleData): Observable<OGantryHttpResponse<GlobalDetailList>> {
    return this.http.post<OGantryHttpResponse<GlobalDetailList>>(`${ApiUrl.globalDetails}`, { global_detail: roleData });
  }

  addTagCategory(categoryData): Observable<OGantryHttpResponse<GlobalDetailTaggingCategory>> {
    return this.http.post<OGantryHttpResponse<GlobalDetailTaggingCategory>>(`${ApiUrl.globalDetails}`, { global_detail: categoryData });
  }

  addTagSubCategory(categoryData): Observable<OGantryHttpResponse<GlobalDetailSubCategory>> {
    return this.http.post<OGantryHttpResponse<GlobalDetailSubCategory>>(`${ApiUrl.globalDetails}`, { global_detail: categoryData });
  }

  addTag(tagData): Observable<OGantryHttpResponse<GlobalDetailTags>> {
    return this.http.post<OGantryHttpResponse<GlobalDetailTags>>(`${ApiUrl.globalDetails}`, { global_detail: tagData });
  }
  addExtendedField(ExtendedField): Observable<OGantryHttpResponse<GlobalDetailTags>> {
    return this.http.post<OGantryHttpResponse<GlobalDetailTags>>(`${ApiUrl.globalDetails}`, { global_detail: ExtendedField });
  }

  setRoles(roles) {
    this.roles = roles;
  }

  setTagCategories(categories) {
    this.tagCategories = categories;
  }

  setTagSubCategories(categories) {
    this.subCategories = categories;
  }

  setTags(tags) {
    this.tags = tags;
  }
  setExtendedFiled(extended_Filed) {
    this.extended_Filed = extended_Filed;
  }

  getManageWorkExceptions(queryFilter): Observable<HttpResponse<OGantryHttpResponse<WorkExceptionList>>> {
    return this.http.get<OGantryHttpResponse<WorkExceptionList>>(ApiUrl.workExceptions, { params: queryFilter, observe: 'response' });
  }

  getExceptionTypes(): Observable<OGantryHttpResponse<WorkExceptionTypes>> {
    return this.http.get<OGantryHttpResponse<WorkExceptionTypes>>(ApiUrl.workExceptionTypes);
  }

  getHolidayTypes(): Observable<OGantryHttpResponse<WorkExceptionTypes>> {
    return this.http.get<OGantryHttpResponse<WorkExceptionTypes>>(ApiUrl.holidayTypes);
  }

  getEmployeeList(queryFilter): Observable<OGantryHttpResponse<EmployeeList>> {
    return this.http.get<OGantryHttpResponse<EmployeeList>>(ApiUrl.employee, { params: queryFilter });
  }

  createWorkException(exceptionData): Observable<OGantryHttpResponse<NormalWorkException>> {
    return this.http.post<OGantryHttpResponse<NormalWorkException>>(`${ApiUrl.workExceptions}/multi`, { work_exceptions: exceptionData });
  }

  getWorkException(id: number): Observable<OGantryHttpResponse<NormalWorkException>> {
    return this.http.get<OGantryHttpResponse<NormalWorkException>>(`${ApiUrl.workExceptions}/${id}`);
  }

  updateWorkException(exceptionData, id): Observable<OGantryHttpResponse<NormalWorkException>> {
    return this.http.put<OGantryHttpResponse<NormalWorkException>>(`${ApiUrl.workExceptions}/${id}`, { work_exception: exceptionData });
  }

  deleteWorkException(id: number) {
    return this.http.delete(`${ApiUrl.workExceptions}/${id}`);
  }

  createHolidayWorkException(exceptionData): Observable<OGantryHttpResponse<HolidayWorkException>> {
    return this.http.post<OGantryHttpResponse<HolidayWorkException>>(ApiUrl.holidayWorkExceptions, { holiday: exceptionData });
  }

  getManageHolidayWorkExceptions(queryFilter?: any): Observable<HttpResponse<OGantryHttpResponse<WorkExceptionList>>> {
    return this.http.get<OGantryHttpResponse<WorkExceptionList>>(ApiUrl.holidayWorkExceptions, { params: queryFilter, observe: 'response' });
  }

  deleteHolidayWorkException(id: number) {
    return this.http.delete(`${ApiUrl.holidayWorkExceptions}/${id}`);
  }

  getHolidayWorkException(id: number): Observable<OGantryHttpResponse<HolidayWorkException>> {
    return this.http.get<OGantryHttpResponse<HolidayWorkException>>(`${ApiUrl.holidayWorkExceptions}/${id}`);
  }

  updateHolidayWorkException(exceptionData, id): Observable<OGantryHttpResponse<HolidayWorkException>> {
    return this.http.put<OGantryHttpResponse<HolidayWorkException>>(`${ApiUrl.holidayWorkExceptions}/${id}`, { holiday: exceptionData });
  }

  getExpenseTypes(queryFilter?: any): Observable<HttpResponse<OGantryHttpResponse<DailyExpenseTypeList>>> {
    return this.http.get<OGantryHttpResponse<DailyExpenseTypeList>>(`${ApiUrl.dailyExpenseType}`, { params: queryFilter, observe: 'response' });
  }

  deleteExpenseType(id: number) {
    return this.http.delete(`${ApiUrl.dailyExpenseType}/${id}`);
  }

  createExpenseType(data): Observable<OGantryHttpResponse<DailyExpenseType>> {
    return this.http.post<OGantryHttpResponse<DailyExpenseType>>(`${ApiUrl.dailyExpenseType}`, { 'daily_expense_type': data });
  }

  getExpenseType(id): Observable<OGantryHttpResponse<DailyExpenseType>> {
    return this.http.get<OGantryHttpResponse<DailyExpenseType>>(`${ApiUrl.dailyExpenseType}/${id}`);
  }

  updateExpenseType(data, id): Observable<OGantryHttpResponse<DailyExpenseType>> {
    return this.http.put<OGantryHttpResponse<DailyExpenseType>>(`${ApiUrl.dailyExpenseType}/${id}`, { 'daily_expense_type': data });
  }

  getEmployeeTypes(queryFilter?: any): Observable<HttpResponse<OGantryHttpResponse<EmployeeTypesList>>> {
    return this.http.get<OGantryHttpResponse<EmployeeTypesList>>(`${ApiUrl.employeeTypes}`, { params: queryFilter, observe: 'response' });
  }

  deleteEmployeeType(id: number) {
    return this.http.delete(`${ApiUrl.employeeTypes}/${id}`);
  }

  getEmployeeType(id): Observable<OGantryHttpResponse<EmployeeType>> {
    return this.http.get<OGantryHttpResponse<EmployeeType>>(`${ApiUrl.employeeTypes}/${id}`);
  }

  updateEmployeeType(data, id): Observable<OGantryHttpResponse<EmployeeType>> {
    return this.http.put<OGantryHttpResponse<EmployeeType>>(`${ApiUrl.employeeTypes}/${id}`, { 'employee_type': data });
  }

  createEmployeeType(data): Observable<OGantryHttpResponse<EmployeeType>> {
    return this.http.post<OGantryHttpResponse<EmployeeType>>(`${ApiUrl.employeeTypes}`, { 'employee_type': data });
  }

  getPositionTypes(queryFilter?: any): Observable<HttpResponse<OGantryHttpResponse<PositionTypeList>>> {
    return this.http.get<OGantryHttpResponse<PositionTypeList>>(`${ApiUrl.positionType}`, { params: queryFilter, observe: 'response' });
  }

  deletePositionType(id: number) {
    return this.http.delete(`${ApiUrl.positionType}/${id}`);
  }

  getPositionType(id): Observable<OGantryHttpResponse<PositionType>> {
    return this.http.get<OGantryHttpResponse<PositionType>>(`${ApiUrl.positionType}/${id}`);
  }

  updatePositionType(data, id): Observable<OGantryHttpResponse<PositionType>> {
    return this.http.put<OGantryHttpResponse<PositionType>>(`${ApiUrl.positionType}/${id}`, { 'position_type': data });
  }

  createPositionType(data): Observable<OGantryHttpResponse<PositionType>> {
    return this.http.post<OGantryHttpResponse<PositionType>>(`${ApiUrl.positionType}`, { 'position_type': data });
  }

  getActiveEmployees(): Observable<Ids> {
    return this.http.get<Ids>(`${ApiUrl.employee}/ids`);
  }

  getMonthlyExpenseTypes(queryFilter?: any): Observable<HttpResponse<OGantryHttpResponse<MonthlyExpenseTypeList>>> {
    return this.http.get<OGantryHttpResponse<MonthlyExpenseTypeList>>(`${ApiUrl.monthlyExpenseType}`, { params: queryFilter, observe: 'response' });
  }

  deleteMonthlyExpenseType(id: number) {
    return this.http.delete(`${ApiUrl.monthlyExpenseType}/${id}`);
  }

  createMonthlyExpenseType(data): Observable<OGantryHttpResponse<MonthlyExpenseType>> {
    return this.http.post<OGantryHttpResponse<MonthlyExpenseType>>(`${ApiUrl.monthlyExpenseType}`, { 'monthly_expense_type': data });
  }

  getMonthlyExpenseType(id): Observable<OGantryHttpResponse<MonthlyExpenseType>> {
    return this.http.get<OGantryHttpResponse<MonthlyExpenseType>>(`${ApiUrl.monthlyExpenseType}/${id}`);
  }

  updateMonthlyExpenseType(data, id): Observable<OGantryHttpResponse<MonthlyExpenseType>> {
    return this.http.put<OGantryHttpResponse<MonthlyExpenseType>>(`${ApiUrl.monthlyExpenseType}/${id}`, { 'monthly_expense_type': data });
  }
  deleteWorkExceptionType(id: number) {
    return this.http.delete(`${ApiUrl.workExceptionTypes}/${id}`);
  }
  deleteHolidayType(id: number) {
    return this.http.delete(`${ApiUrl.holidayTypes}/${id}`);
  }
  getWorkExceptionType(id): Observable<OGantryHttpResponse<WorkExceptionType>> {
    return this.http.get<OGantryHttpResponse<WorkExceptionType>>(`${ApiUrl.workExceptionTypes}/${id}`);
  }
  createWorkExceptionType(data): Observable<OGantryHttpResponse<WorkExceptionType>> {
    return this.http.post<OGantryHttpResponse<WorkExceptionType>>(`${ApiUrl.workExceptionTypes}`, { 'work_exception_type': data });
  }
  updateWorkExceptionType(data, id): Observable<OGantryHttpResponse<WorkExceptionType>> {
    return this.http.put<OGantryHttpResponse<WorkExceptionType>>(`${ApiUrl.workExceptionTypes}/${id}`, { 'work_exception_type': data });
  }
  getHolidayType(id): Observable<OGantryHttpResponse<WorkExceptionType>> {
    return this.http.get<OGantryHttpResponse<WorkExceptionType>>(`${ApiUrl.holidayTypes}/${id}`);
  }
  updateHolidayType(data, id): Observable<OGantryHttpResponse<WorkExceptionType>> {
    return this.http.put<OGantryHttpResponse<WorkExceptionType>>(`${ApiUrl.holidayTypes}/${id}`, { 'work_exception_type': data });
  }
  createHolidayType(data): Observable<OGantryHttpResponse<WorkExceptionType>> {
    return this.http.post<OGantryHttpResponse<WorkExceptionType>>(`${ApiUrl.holidayTypes}`, { 'work_exception_type': data });
  }

  getEmployeeFinancialHistory(employeeId): Observable<OGantryHttpResponse<EmployeeFinancial>> {
    return this.http.get<OGantryHttpResponse<EmployeeFinancial>>(`${ApiUrl.employeeFinanacial}/${employeeId}`);
  }
  getAuditHistory(employeeId): Observable<OGantryHttpResponse<EmployeeList>> {
    return this.http.get<OGantryHttpResponse<EmployeeList>>(`${ApiUrl.employee}/${employeeId}/audit`);
  }
  getPendingJobs(employeeId): Observable<OGantryHttpResponse<JobList>> {
    return this.http.get<OGantryHttpResponse<JobList>>(`${ApiUrl.employee}/${employeeId}/jobs`);
  }

  appendTagToAnEmployee(employeId: string, data: string[]) {
    return this.http.put<OGantryHttpResponse<WorkExceptionType>>(`${ApiUrl.appendTagToAnEmployee.replace('{employee_id}', employeId)}`, { 'tags': data });
  }

  deleteTagFromAnEmployee(employeId: string, data: string[]) {
    return this.http.put<OGantryHttpResponse<WorkExceptionType>>(`${ApiUrl.deleteTagFromAnEmployee.replace('{employee_id}', employeId)}`, { 'tags': data });
  }

  appendTagToACleint(clientId: string, data: string[]) {
    return this.http.put(`${ApiUrl.appendTagToAClient.replace('{client_id}', clientId)}`, { 'tags': data });
  }

  deleteTagFromACleint(clientId: string, data: string[]) {
    return this.http.put(`${ApiUrl.deleteTagFromAClient.replace('{client_id}', clientId)}`, { 'tags': data });
  }

  getEmployeeIds(): Observable<EmployeeIds> {
    return this.http.get<EmployeeIds>(`${ApiUrl.employeeIds}`);
  }

  appendTagToAProject(projectId: string, data: string[]) {
    return this.http.put(`${ApiUrl.appendTagToAProject.replace('{project_id}', projectId)}`, { 'tags': data });
  }

  deleteTagFromAProject(projectId: string, data: string[]) {
    return this.http.put(`${ApiUrl.deleteTagFromAProject.replace('{project_id}', projectId)}`, { 'tags': data });
  }

  appendTagToAPosition(positionId: string, data: string[]) {
    return this.http.put(`${ApiUrl.appendTagToAPosition.replace('{position_id}', positionId)}`, { 'tags': data });
  }

  deleteTagFromAPosition(positionId: string, data: string[]) {
    return this.http.put(`${ApiUrl.deleteTagFromAPosition.replace('{position_id}', positionId)}`, { 'tags': data });
  }
}
