export class SocialNewAuthResponse {
  session_params: { state: string };
  url: string;
}

export enum SocialLoginOption {
  GITHUB = 1,
  GOOGLE,
  MICROSOFT
}

export class SocialAuthRequest {
  session_params: { state: string };
  scope?: string;
  code?: string;
  redirect_uri?: string;
  prompt?: string;
}

export class GoogleAuthRequest extends SocialAuthRequest {
  constructor() {
    super();
    this.prompt = 'none';
    this.scope = 'email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid';
  }
}

export class GithubAuthRequest extends SocialAuthRequest { }
export class MicrosoftAuthRequest extends SocialAuthRequest { }

export interface ReleaseVersion {
  release_version?: {
    notes?: string;
    version?: string;
  }
}

export function getModelType(socialLoginOption): SocialAuthRequest {
  switch (socialLoginOption) {
    case SocialLoginOption.GOOGLE: return new GoogleAuthRequest();
    case SocialLoginOption.GITHUB: return new GithubAuthRequest();
    case SocialLoginOption.MICROSOFT: return new MicrosoftAuthRequest();
  }
}
