/**
 * @sunflowerlab
 * @author Ashish Kumar
 */
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ButtonParams } from "@shared/models";
import { PermissionModules } from "@shared/models/permission.enum";
import { SflBaseComponent } from "../sfl-base/sfl-base.component";

@Component({
  selector: "app-card-header",
  templateUrl: "./card-header.component.html",
  styleUrls: ["./card-header.component.scss"],
})
export class CardHeaderComponent extends SflBaseComponent {
  @Input() buttons: ButtonParams[] = [];
  @Input() cardTitle: string;
  @Input() cardSubTitle: string;
  @Input() cardLabelClass: string;
  @Input() exportButton: ButtonParams[] = [];
  @Input() showHideButton = [];
  @Input() permissions: PermissionModules[] = [];
  @Input() splitButtonDropDownOption: any;
  @Input() showSplitButton: boolean;
  @Input() showFilterButton: boolean = false;
  items = [
    {
      label: "CSV",
      icon: "pi pi-file-o",
      command: () => {
        this.exportButton[0].action("csv");
      },
    },
    {
      label: "PDF",
      icon: "pi pi-file-pdf",
      command: () => {
        this.exportButton[0].action("pdf");
      },
    },
    {
      label: "Excel",
      icon: "pi pi-file-excel",
      command: () => {
        this.exportButton[0].action("excel");
      },
    },
  ];
  showHideColumns = [
    {
      label: "Show Columns",
      icon: "pi pi-eye",
      command: () => {
        this.showHideButton[0].action("showColumns");
      },
    },
    {
      label: "Hide Columns",
      icon: "pi pi-times",
      command: () => {
        this.showHideButton[0].action("hideColumns");
      },
    },
  ];
  constructor(private readonly router: Router) {
    super();
  }

  onAction(button: ButtonParams) {
    localStorage.removeItem("isTableView");
    if (button.action) {
      button.action();
      localStorage.setItem("isTableView", button.viewType);
    } else {
      this.router.navigate([button.redirectPath]);
    }
  }

  onClickOfSplitButton() {
    this.splitButtonDropDownOption.action();
  }
}
