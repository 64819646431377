<!--begin::User-->
<div ngbDropdown placement="top-right" *ngIf="user$ | async as _user" class="dropdown" display="dynamic" container="body" >
  <!--begin::Toggle-->
  <div ngbDropdownToggle class="topbar-item">
    <div class="btn btn-icon w-auto d-flex align-items-center btn-lg px-6">
      <!-- <span class="text-white font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span> -->
      <span class="text-white font-weight-bolder font-size-base d-none d-md-inline mr-3">Sign Out</span>
      <!-- <span class="symbol symbol-35 symbol-light-success">
        <span class="text-white font-weight-bolder font-size-base d-none d-md-inline mr-3">{{
          (_user?.lastName + ' ' + _user?.firstName) | firstLetter
          }}</span>
      </span> -->
      <span class="text-white ml-24">
        <fa-icon [icon]="'chevron-right'"></fa-icon>
      </span>

    </div>
  </div>
  <!--end::Toggle-->

  <!--begin::Dropdown-->
  <div ngbDropdownMenu class=" dropdown-left dropdown-menu p-0 m-0 dropdown-menu-bottom dropdown-menu-anim-up dropdown-menu-lg p-0">
    <ng-container>
      <div class="d-block align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top user-profile-dropdown-img">
        <div class="d-flex align-items-center mr-2">
          <!-- <div class="symbol bg-white-o-15 mr-3">
            <span class="symbol-label text-success font-weight-bold font-size-h4">{{ _user?.lastName | firstLetter }}</span>
          </div> -->

          <div class="text-white m-0 flex-grow-1 mr-3 font-size-h5">
            {{ currentRole }}
          </div>
        </div>
        <div class="text-white m-0 flex-grow-1 mr-3 font-size-h6">
          {{ userEmail}}
        </div>

      </div>
    </ng-container>
    <!--begin::Nav-->
    <div class="navi navi-spacer-x-0 pt-5">
      <!--begin::Item-->

      <!--end::Item-->

      <!--begin::Footer-->
      <div class="navi-footer px-8 py-5">
        <a href="javascript:;" (click)="logout()" class="btn btn-light-primary font-weight-bold">Sign Out</a>
        <div class="text-right m-0 flex-grow-1 mr-3 font-size-h6">
          <a (click)="openPrivacyPolicy()" class="btn font-weight-bold" style="text-decoration:underline">Privacy Policy</a>
        </div>
      </div>
      <div class="btn-light-primary font-weight-bold px-8 m-0 flex-grow-1 mr-3 font-size-h6" style="color: #4B3F72;">
        Version:- {{currentApplicationVersion}} : FE &nbsp;   {{backendVersion}} : BE
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Nav-->
  </div>
  <!--end::Dropdown-->
</div>
<!--end::User-->
