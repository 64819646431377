<div class="card card-custom add-contact">
  <app-card-header [cardTitle]="filterCardTitle" [buttons]="filterButtons" [cardLabelClass]="'mb-0'"></app-card-header>
  <div class="card-body create-card">
    <form class="form" autocomplete="off" novalidate="novalidate" id="applyFilter">
      <div class="row">
        <div class="col-12">
          <div class="PL-border">
            <div class="form-group">
              <label class="form-label background">
                <div class="width-65">
                  Variance Period
                </div>
                <div class="form-group pt-0 save-filter-radio">
                  <div class="form-check form-check-inline mt-2">
                    <input class="form-check-input" type="radio" name="variance" [checked]="filterData.showCustomVariancePeriod" id="byvariance" autocomplete="off" (click)="customVarianceBtnSelected()">
                    <label class="mb-0" for=byvariance>
                   Custom Variance Period
                    </label>
                  </div>

                </div>
              </label>
            </div>
            <div *ngIf="!filterData.showCustomVariancePeriod">
              <div class="form-group first dropdown p-2">
                <p-dropdown appendTo="body" [(ngModel)]="filterData.varianceValue" name="variance" [options]="varianceArray" placeholder="Select"></p-dropdown>
              </div>
            </div>
            <div *ngIf="filterData.showCustomVariancePeriod">
              <div class="row mt-4 p-2">
                <div class="col-6">
                  <div class="form-group pb-0">
                    <label class="form-label">Previous</label>
                  </div>
                  <div class="form-group pt-0 range-calender">
                    <p-calendar appendTo="body" hourFormat="12" [minDate]="minEffectiveDate" [(ngModel)]="filterData.effective_date1" [showTime]="true" placeholder="Select Date" class="form-control custom" [showIcon]="true" inputId="date1"
                    name="date1"></p-calendar>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group pb-0">
                    <label class="form-label"> Current Baseline</label>
                  </div>
                  <div class="form-group pt-0 range-calender">
                    <p-calendar appendTo="body" hourFormat="12" [minDate]="filterData.effective_date1" [(ngModel)]="filterData.effective_date2" [showTime]="true" placeholder="Select Date" class="form-control custom" [showIcon]="true" inputId="date2"
                name="date2"></p-calendar>
                  </div>
                </div>
              </div>
            </div>
           </div>

           <app-shared-range-selector 
           [showDateRangeRadioButton]="filterData.start_month && filterData.end_month ? true : false"
           [rollingRadioBtnSelected]="filterData.rollingOption ? true : false"
           [yearRadioBtnSelected]="filterData.year ? true : false"
           [quaterRadioBtnSelected]="filterData.quarter ? true : false"
           [start_month]="filterData.start_month"
           [end_month]="filterData.end_month" 
           [selectedYear]="filterData.year"
           [selectedQuarterValue]="filterData.quarter" 
           [selectedRollingOption]="filterData.rollingOption"
           (selected_end_date)="endMonthSelected($event)" 
           (selected_start_date)="startMonthSelected($event)"
           (updatedYearValue)="filterData.year = $event" 
           (updatedRollingValue)="filterData.rollingOption = $event"
           (updatedquaterValue)="filterData.quarter = $event" 
           [dateError]="dateError"
           [dateRequired]="dateRequired"></app-shared-range-selector>
          <div class="PL-border">
            <div class="form-group">
              <label class="form-label background ">Project Status</label>
            </div>
            <div class="form-group first dropdown p-2">
              <p-multiSelect #multiSelectComp [overlayVisible]="false" [showHeader]="false" [(ngModel)]="filterData.status" name="statuses"  [options]="statuses" placeholder="Status" display="chip" (onChange)="statusSelected($event)">
              </p-multiSelect>
            </div>
          </div>
          <div class="PL-border">
            <div class="form-group">
              <label class="form-label background">
                <div class="width-65">
                  {{ filterData?.showClientFilter ? 'Saved Client Filter' : 'Client' }}
                </div>
                <div class="form-group pt-0 save-filter-radio">
                  <div class="form-check form-check-inline mt-2">
                    <input class="form-check-input" type="radio" id="saveClientFilter" [checked]="filterData?.showClientFilter" autocomplete="off" (click)="showSaveClientFilterSelected()" >
                    <label class="mb-0" for=saveClientFilter>
                     Use Saved Client Filter
                    </label>
                  </div>
                </div>
              </label>
            </div>
            <div class="form-group first dropdown p-2" *ngIf="filterData?.showClientFilter">
              <p-dropdown appendTo="body" [(ngModel)]="filterData.customer_name" name="clientGroup" [options]="_clientGroups" placeholder="Select" (onChange)="_getClientsIds()"></p-dropdown>
            </div>
            <div class="form-group first dropdown p-2" *ngIf="!filterData?.showClientFilter">
              <p-multiSelect appendTo="body" #multiSelectComp2 [(ngModel)]="filterData.selectedClient" [overlayVisible]="false" [showHeader]="false" [options]="client" [ngModelOptions]="{standalone: true}" placeholder="Select"
              display="chip" (onChange)="clientSelected($event)">
              </p-multiSelect>
            </div>
          </div>
          <div class="PL-border">
            <div class="form-group">
              <label class="form-label background">
                <div class="width-65">
                  {{ filterData?.showProjectFilter ? 'Saved Project Filter' : 'Project' }}
                </div>
                <div class="form-group pt-0 save-filter-radio">
                  <div class="form-check form-check-inline mt-2">
                    <input class="form-check-input" type="radio" id="saveProjectFilter" [checked]="filterData?.showProjectFilter" autocomplete="off" (click)="showSaveProjectFilterSelected()" >
                    <label class="mb-0" for=saveProjectFilter>
                     Use Saved Project Filter
                    </label>
                  </div>
                </div>
              </label>
            </div>
            <div class="form-group first dropdown p-2" *ngIf="filterData?.showProjectFilter">
              <p-dropdown appendTo="body" [(ngModel)]="filterData.project_name" name="projectGroup" [options]="_projectGroups" placeholder="Select" (onChange)="_getProjectsIds()"></p-dropdown>
            </div>
            <div class="form-group first dropdown p-2" *ngIf="!filterData?.showProjectFilter">
              <p-multiSelect appendTo="body" #multiSelectComp3 [(ngModel)]="filterData.selectedProject" [overlayVisible]="false" [showHeader]="false" [options]="project" [ngModelOptions]="{standalone: true}" placeholder="Select"
              display="chip" (onChange)="projectSelected($event)">
            </p-multiSelect>
            </div>
          </div>
          <div class="PL-border">
            <div class="form-group">
              <label class="form-label background ">Tags</label>
            </div>
            <div class="form-group first dropdown p-2">
              <p-treeSelect [(ngModel)]="savedSelectedTags" (ngModelChange)="tagSelected($event)" class="filter-tags"
                [options]="tags" display="chip" [metaKeySelection]="false" selectionMode="checkbox"
                placeholder="Select Tags" [ngModelOptions]="{standalone: true}" filterBy="label">
              </p-treeSelect>
            </div>
          </div>
          <div class="form-group d-flex">
            <div class="mr-5 ml-2">
              <input type="checkbox" [(ngModel)]="filterData.include_utilizations" id="include_utilization" name="include_utilization"class="form-check" />
            <label for="include_utilization" class="label-form-check">Include Bench in P&L</label>
            </div>
            <div class="mr-5 ml-2">
              <input type="checkbox" [(ngModel)]="filterData.include_pl_plugs" id="include_pl_plugs" name="include_pl_plugs"class="form-check" />
              <label for="include_pl_plugs" class="label-form-check">Include P&L Plugs</label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group d-flex flex-wrap justify-content-end align-items-center mt-2 pb-4">
        <button id="addContactCancel" (click)="onReset()" type="button" class="btn-cancel">
          Reset
        </button>
        <button id="addContactSubmit" (click)="onSubmit()" type="submit" [isSubmitting]="isSubmitting" class="btn-save">
          Apply
        </button>
      </div>
    </form>
  </div>
</div>
