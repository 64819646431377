import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSflAutofocus]'
})
export class SflAutofocusDirective implements AfterViewInit {

  constructor(
    private elementRef: ElementRef
  ) { }

  ngAfterViewInit() {
    this.elementRef.nativeElement.focus();
  }
}
