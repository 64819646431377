export class PageConfig {
  public defaults: any = {
    dashboard: {
      page: {
        title: "Dashboard",
        desc: "Latest updates and statistic charts",
      },
    },
    client: {
      manage: {
        page: {
          title: "Manage Clients",
          desc: "",
        },
      },
      create: {
        page: {
          title: "Create Client",
          desc: "",
        },
      }
    },
    project: {
      manage: {
        page: {
          title: "Manage Projects",
          desc: "",
        },
      },
      create: {
        page: {
          title: "Create Project",
          desc: "",
        },
      }
    },
    'utilization-management': {
      'bench-report': {
        page: {
          title: "Utilization Report",
          desc: "",
        },
      },
      'open-position-report': {
        page: {
          title: "Open Position Report",
          desc: "",
        },
      }
    },
    'financial-forecasting': {
      'manage-P&L': {
        page: {
          title: "Manage P&L",
          desc: "",
        },
      },
      'compare-P&L': {
        page: {
          title: "P&L Comparison",
          desc: "",
        },
      }
    },
    admin: {
      'work-exceptions': {
        manage: {
          page: {
            title: "Manage Work Exceptions",
            desc: "",
          },
        },
        create: {
          page: {
            title: "Add Work Exceptions",
            desc: "",
          },
        }
      },
      'expense-type': {
        manage: {
          page: {
            title: "Manage Daily Expense Types",
            desc: "",
          },
        },
        create: {
          page: {
            title: "Add Daily Expense Type",
            desc: "",
          },
        }
      },
      'monthly-expense-type': {
        manage: {
          page: {
            title: "Manage Monthly Expense Types",
            desc: "",
          },
        },
        create: {
          page: {
            title: "Add Monthly Expense Type",
            desc: "",
          },
        }
      },
      'position-type': {
        manage: {
          page: {
            title: "Manage Skill Set",
            desc: "",
          },
        },
        create: {
          page: {
            title: "Add Skill Set",
            desc: "",
          },
        }
      },
      'employees-type': {
        manage: {
          page: {
            title: "Manage Employee Type",
            desc: "",
          },
        },
        create: {
          page: {
            title: "Add Employee Type",
            desc: "",
          },
        }
      },
      manage: {
        page: {
          title: "Manage Employee Type",
          desc: "",
        },
        create: {
          page: {
            title: "Add Employee Type",
            desc: "",
          },
        }
      },
      employee: {
        manage: {
          page: {
            title: "Manage Employees",
            desc: "",
          },
        },
        create: {
          page: {
            title: "Create Employee",
            desc: "",
          },
        }
      },
      role: {
        manage: {
          page: {
            title: "Manage Permissions",
            desc: "",
          },
        },
        create: {
          page: {
            title: "Create Role",
            desc: "",
          },
        }
      },
    },
    users: {
      page: { title: "Users", desc: "" },
    },
    forbidden: {
      page: { title: "", desc: "" },
    },
    profile: {
      page: { title: "User Profile", desc: "" },
    },
    error: {
      404: {
        page: { title: "404 Not Found", desc: "", subheader: false },
      },
      403: {
        page: { title: "403 Access Forbidden", desc: "", subheader: false },
      },
    },
    wizard: {
      page: { title: "Wizard", desc: "" },
    },
  };

  public get configs(): string {
    return this.defaults;
  }
}
