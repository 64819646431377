import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomerContact } from '../../models/custom/customer-contact.model';
import { ApiUrl } from '@shared/constants';

@Injectable({ providedIn: 'root' })
export class ContactPersonService {
  constructor(private readonly httpClient: HttpClient) { }

  createContact(contact: CustomerContact): Observable<CustomerContact> {
    return this.httpClient.post<CustomerContact>(ApiUrl.clientContact, { customer_contact: contact });
  }
  updateContact(contact: CustomerContact, contactId: number): Observable<CustomerContact> {
    return this.httpClient.put<CustomerContact>(`/contacts/${contactId}/customer`, { customer_contact: contact });
  }

  createProjectContact(contact: CustomerContact): Observable<CustomerContact> {
    return this.httpClient.post<CustomerContact>(ApiUrl.projectContact, { project_contact: contact });
  }

  updateProjectContact(contact: CustomerContact, contactId: number): Observable<CustomerContact> {
    return this.httpClient.put<CustomerContact>(`/contacts/${contactId}/project`, { project_contact: contact });
  }

  deleteContactPerson(id) {
    return this.httpClient.delete(`/contacts/${id}/customer`);
  }

  deleteProjectContactPerson(id) {
    return this.httpClient.delete(`/contacts/${id}/project`);
  }
}
