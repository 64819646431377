import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { AlertType } from '@shared/models/alert-type.enum';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { AdministrationService } from '../administration.service';
import { AppendTagsComponent } from '../append-tags/append-tags.component';

@Component({
  selector: 'app-tag-listing-view',
  templateUrl: './tag-listing-view.component.html',
  styleUrls: ['./tag-listing-view.component.scss']
})
export class TagListingViewComponent extends SflBaseComponent implements OnInit {

  @Input() _employeeId: number;
  @Input() _tags: string[];
  @Input() _flag: string;
  @Output() _reloadTags = new EventEmitter<boolean>();
  showDeleteDialog = false;
  deletingTag: string;
  deletingTagIndex: number;
  constructor(private readonly dialog: MatDialog,
    private readonly adminService: AdministrationService,
    private readonly layoutUtilsService: LayoutUtilsService) {
    super();
  }

  ngOnInit(): void {
  }

  openAddNewTagsDialog() {
    const dialogRef = this.dialog.open(AppendTagsComponent, {
      data: {tags: this._tags ?? [], employeeId: this._employeeId, title: 'Add Tag', flag: this._flag},
      width: '880px'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this._reloadTags.emit(true);
      }
    });
  }

  getTag(tag: string) {
    return tag.split('__').pop();
  }

  initiateRemoveTag(tag: string, tooltip, index: number) {
    this.showDeleteDialog = true;
    this.deletingTag = tag;
    this.deletingTagIndex = index;
    tooltip.close();
  }

  deleteTag() {
    this.showDeleteDialog = false;
    switch (this._flag) {
      case 'EMPLOYEE_TAG':
        this.subscriptionManager.add(this.adminService.deleteTagFromAnEmployee(this._employeeId.toString(), [this.deletingTag]).subscribe(res => {
          this.layoutUtilsService.showActionNotification('Tag has been removed successfully', AlertType.Success);
          this._tags.splice(this.deletingTagIndex,1);
          this.showDeleteDialog = false;
        }))
        break;
      case 'CLIENT_TAG':
        this.subscriptionManager.add(this.adminService.deleteTagFromACleint(this._employeeId.toString(), [this.deletingTag]).subscribe(res => {
          this.layoutUtilsService.showActionNotification('Tag has been removed successfully', AlertType.Success);
          this._tags.splice(this.deletingTagIndex,1);
          this.showDeleteDialog = false;
        }))
        break;
      case 'PROJECT_TAG':
        this.subscriptionManager.add(this.adminService.deleteTagFromAProject(this._employeeId.toString(), [this.deletingTag]).subscribe(res => {
          this.layoutUtilsService.showActionNotification('Tag has been removed successfully', AlertType.Success);
          this._tags.splice(this.deletingTagIndex, 1)
          this.showDeleteDialog = false;
        }))
        break;
    }
  }

  onSuccess() {
    this.layoutUtilsService.showActionNotification('Tag added successfully', AlertType.Success);
  }

  closeModal() {
    this._tags.splice(this.deletingTagIndex,0,this.deletingTag);
    this.deletingTag = null;
    this.showDeleteDialog = false;
  }

  getTagCategorySubCategory(tag: string): string {
    const tagArray = tag.split('__');
    const categoryName = tagArray[0];
    const subCategory = tagArray?.length > 2 ? tagArray[1] : null;
    if (subCategory)
      return `Category <strong>${categoryName}</strong> <br> Sub Category<strong>${subCategory}</strong>`;
    else
      return `Category <strong>${categoryName}</strong>`;
  }

  toggleWithCategory(tooltip, tag) {
    if (tooltip.isOpen()) {
      tooltip.close();
    } else {
      tooltip.open({ tag });
    }
  }
}
