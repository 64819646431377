<div class="card card-custom" [ngClass]="{'card-body-progress' : viewLoading, 'card-body-progress card-body-progress-overlay' : loadingAfterSubmit }">
  <div class="card-header">
    <div class="card-title mb-0">
      <h3 class="card-label mb-0">{{appendDataCollection?.title}}</h3>
    </div>
  </div>
  <div class="card card-custom">
    <form class="form" [formGroup]="tagsForm" (ngSubmit)="tagsForm.valid && saveTags()">
      <div class="card-body">
        <kt-auth-notice></kt-auth-notice>
        <div class="row">
          <div class="col-md-12">
            <label class="form-label mr-3">Select Tag</label>
            <p-treeSelect formControlName="selectedTags" class="append-tags-pTreeSelect" [options]="groupedCategory?.data" display="chip" [metaKeySelection]="false" selectionMode="checkbox" placeholder="Select Tags"></p-treeSelect>
          </div>
        </div>
        <div class="tag-listing" *ngIf="appendDataCollection.tags?.length">
          <h5>Selected Tags:</h5>
          <p-chip *ngFor="let tag of appendDataCollection.tags; let i=index" class="pr-2 pb-2 append-tags-pTags" [ngbTooltip]="categoryDetails" #t2="ngbTooltip" (mouseenter)="toggleWithCategory(t2, tag)" [label]="getTag(tag)" [removable]="true" (onRemove)="initiateRemoveTag(tag, t2, i)"></p-chip>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="d-flex justify-content-end">
              <div class="form-group d-flex flex-wrap mt-2">
                <button id="addContactCancel" type="button" class="btn-cancel" (click)="cancel()">Cancel</button>
                <button id="addContactSubmit" type="submit" class="btn-save" [isSubmitting]="isSubmitting">Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #categoryDetails let-tag="tag">
  <p [innerHTML]="getTagCategorySubCategory(tag)"></p>
</ng-template>

<p-dialog header="Delete" [(visible)]="showDeleteDialog" [modal]="true" class="confirm-dialog" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
  <h5 class="p-m-0 delete-title">Do you want to delete this tag?</h5>
  <ng-template pTemplate="footer">
    <div class="d-flex flex-wrap justify-content-end align-items-center">
      <button type="button" class="btn-cancel" (click)="closeModal()">No</button>
      <button type="button" class="btn-save" (click)="deleteTag()" [isSubmitting]="isSubmitting">Yes</button>
    </div>
  </ng-template>
</p-dialog>
