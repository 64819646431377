<ng-template #blankLayout>
  <div class="d-flex flex-column flex-root">
    <router-outlet></router-outlet>
  </div>
</ng-template>


<!-- begin:: Page -->
<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
  <!-- begin:: Header Mobile -->
  <kt-header-mobile></kt-header-mobile>
  <!-- end:: Header Mobile -->

  <div class="d-flex flex-column flex-root">
    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <ng-container *ngIf="asideSelfDisplay">
        <kt-aside-left></kt-aside-left>
      </ng-container>
      <!-- end:: Aside Left -->

      <!--begin::Wrapper-->
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

        <!-- begin:: Content -->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content" [ngClass]="contentClasses">
          <!-- begin:: Content Head -->
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <!--begin::Entry-->
          <div ktContentAnimate class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div [ngClass]="contentContainerClasses">
              <router-outlet *ngIf="!(checkAuthGuard?.loader$ | async)"></router-outlet>
              <!--end::Container-->

              <div class="d-flex justify-content-center align-items-center loader-height" *ngIf="checkAuthGuard?.loader$ | async">
                <mat-spinner [diameter]="30"></mat-spinner>
              </div>
            </div>
          </div>
          <!--end::Entry-->
          <!-- end:: Content Body -->

        </div>
        <!-- end:: Content -->

      </div>
      <!--end::Wrapper-->
    </div>
    <!-- end:: Body -->

  </div>
</ng-container>
<!-- end:: Page -->
