/**
 * @sunflowerlab
 * @author Ashish Kumar
 */

export class ErrorMessages {
  public static readonly errorMessage: string = 'Something went wrong, please try again later.';
  public static readonly serverNotAvailable: string = 'Server is not available at the moment. Please try after sometime.';
  public static readonly INVALID_LOGIN: string = 'The login detail is incorrect.';
  public static readonly csvParserError="Errors found in rows. Please correct data and try re-importing."
}
