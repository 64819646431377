import { Component, Input, OnInit, Output ,EventEmitter} from "@angular/core";

@Component({
  selector: "app-time-sheet-table",
  templateUrl: "./time-sheet-table.component.html",
  styleUrls: ["./time-sheet-table.component.scss"],
})
export class TimeSheetTableComponent implements OnInit {
  @Input() tableData;
  @Input() tableHeader;
  @Input() showFilter;
  @Output() correctionData: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void { }
  
  onCorrection(selectedData, columnHeader, index) {
    if (!selectedData[columnHeader.columnField].isMatchWithOG) {
      const data= {
        data: selectedData,
        columnData: columnHeader,
        index:index
      }
      this.correctionData.emit(data);
    }
  }
}
