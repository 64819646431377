export enum ComponentsType {
  Project = 'Project',
  Client = 'Client',
  Employee = 'Employee',
  Position = 'Position'
}


export enum FiledType {
  Text = 'text',
  Text_Area= 'long_text'
}
