<p-table responsiveLayout="scroll" [value]="tableData" [columns]="tableHeader" [scrollable]="true"
    id="common-table--wrapper">
    <!-- selectionMode="multiple" for hover change the color -->
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [class]="col?.class" [ngStyle]="{'width':col?.width}">
                {{ col.header }}
            </th>
        </tr>
        <!-- <tr *ngIf="showFilter">
            <th *ngFor="let col of columns" [ngStyle]="{'width':col.width}">
                <ng-container *ngIf="col.inputPlaceHolder">
                    <span *ngIf="col.inputPlaceHolder !== 'Status';else statusDropDown">
                        <input pInputText type="text" class="p-column-filter" [placeholder]="col.inputPlaceHolder" />
                    </span>
                </ng-container>
                <ng-template #statusDropDown>
                    <p-multiSelect [ngStyle]="{color:'black',fontFamily:'Poppins'}" appendTo="body" #multiSelectComp
                        placeholder="Status" display="chip"> </p-multiSelect>
                </ng-template>
            </th>
        </tr> -->
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-ri="rowIndex">
        <tr>
            <td *ngFor="let col of columns" [ngStyle]="{'width':col?.width }"
                [ngClass]="{'not-macthed-with-OG': rowData[col.columnField]?.isMatchWithOG === false}"
                (click)="onCorrection(rowData,col,ri)">
                <ng-container *ngIf="col?.header !== 'Utilization';else utilization">
                    <!-- <span class="w-100 d-flex justify-content-center">{{ rowData[  col.field] }}</span> -->
                    {{ rowData[col.columnField]?.value }}
                </ng-container>
                <ng-template #utilization>
                    <div class="h-100 w-100 d-flex align-items-center justify-content-around">
                        <div class="h-25 utilization-progress--wrapper">
                            <div class="h-100 actual-utilization-progress"></div>
                        </div>
                        <div class="w-25">{{ rowData[col.field] }}%</div>
                    </div>
                </ng-template>
            </td>
        </tr>
    </ng-template>
</p-table>