import {
  Directive,
  ElementRef
} from '@angular/core';


@Directive({
  selector: '[sflIsPhone]'
})
export class PhoneNumberDirective {
  private readonly el: HTMLInputElement;
  constructor(private readonly elementRef: ElementRef) {
    this.elementRef.nativeElement.onkeypress = event => {
      if ((event.which >= 48 && event.which <= 57) || event.which === 41 || event.which === 40 || event.which === 45 || event.which === 43 || event.which === 46) { }
      else {
        event.preventDefault();
      }
    };
  }
}
