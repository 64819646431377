// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
import { AuthService } from '@auth/auth.service';

@Injectable()
export class MenuAsideService {
  // Public properties
  menuList$: BehaviorSubject<object> = new BehaviorSubject<object>([]);

  /**
   * Service constructor
   *
   * @param menuConfigService: MenuConfigService
   */
  constructor(private readonly menuConfigService: MenuConfigService, private readonly authService: AuthService) {
    this.loadMenu();
    this.menuConfigService.onConfigUpdated$.subscribe(() => {
      this.loadMenu();
    });
  }

  async loadMenu() {
    // get menu list
    const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
    const authorizedMenuItems = await this.loadAuthorizedMenuConfigs(menuItems);
    this.menuList$.next(authorizedMenuItems);
  }

  private async loadAuthorizedMenuConfigs(menuItems) {
    const authorizedMenus = [];
    for (const menu of menuItems) {
      const authorisedSubMenus = [];
      for (const submenu of menu.submenu) {
        if (await this.authService.isPermittedAction(submenu?.permissionModules)) {
          authorisedSubMenus.push(submenu);
        }
      }
      if (authorisedSubMenus.length) {
        authorizedMenus.push({ ...menu, submenu: authorisedSubMenus })
      }
    }
    return authorizedMenus;
  }
}
