<!-- splash screen -->
<div #splashScreen class="splash-screen">
  <!--Preloader-->
  <div id="preloader">
    <div id="ctn-preloader" class="ctn-preloader">
      <div class="animation-preloader">
        <div class="spinner"></div>
        <div class="txt-loading">
          <span data-text-preloader="O" class="letters-loading">
            O
          </span>
          <span data-text-preloader="G" class="letters-loading">
            G
          </span>
          <span data-text-preloader="a" class="letters-loading">
            a
          </span>
          <span data-text-preloader="n" class="letters-loading">
            n
          </span>
          <span data-text-preloader="t" class="letters-loading">
            t
          </span>
          <span data-text-preloader="r" class="letters-loading">
            r
          </span>
          <span data-text-preloader="y" class="letters-loading">
            y
          </span>
        </div>
        <p class="text-center">Loading</p>
      </div>
    </div>
  </div>
  <!--End Preloader-->
</div>
