// Angular
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
// RxJS
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthNoticeService } from './auth-notice.service';
// Auth

@Component({
  selector: "kt-auth-notice",
  templateUrl: "./auth-notice.component.html",
  styles: [
    `
    .alert {
      display: flex;
      justify-content: space-between;
    }
    .alert fa-icon:hover {
      cursor: pointer;
    }
    `
  ]
})
export class AuthNoticeComponent implements OnInit, OnDestroy {
  @Output() type: string;
  @Output() message = '';

  // Private properties
  private readonly subscriptions: Subscription[] = [];

  /**
   * Component Constructure
   *
   * @param authNoticeService
   * @param cdr
   */
  constructor(
    public readonly authNoticeService: AuthNoticeService,
    private readonly cdr: ChangeDetectorRef
  ) { }

  /*
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.subscriptions.push(
      this.authNoticeService.onNoticeChanged$.subscribe(
        (notice: AuthNotice) => {
          notice = Object.assign({}, { message: "", type: "" }, notice);
          this.message = notice.message;
          this.type = notice.type;
          this.cdr.markForCheck();
        }
      )
    );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
}

export interface AuthNotice {
  type?: string;
  message: string;
}
