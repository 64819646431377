import { Directive, Input, ViewContainerRef, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { PermissionModules } from '@shared/models/permission.enum';
import { Router } from '@angular/router';
import { APP_ROUTES } from '@shared/constants/routes.constant';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the permissions passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *hasAnyPermission="PERM_EDIT">...</some-element>
 *
 *     <some-element *hasAnyPermission="['PERM_EDIT', 'PERM_ADD']; ofUser: 3">...</some-element>
 * ```
 */
@Directive({
  selector: '[hasAnyPermission]'
})
export class HasAnyPermissionDirective {
  private permissions: PermissionModules[];
  private _disableEvents = false;
  private _hideTemplate = false;

  constructor(
    private readonly authService: AuthService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly template: TemplateRef<any>,
    private readonly router: Router,
    private readonly cdf: ChangeDetectorRef
  ) { }

  @Input()
  set hasAnyPermission(value: PermissionModules | PermissionModules[]) {
    if (value) {
      this.permissions = Array.isArray(value) ? value : [value];
      this.authService.isPermittedAction(this.permissions)
        .then(isPermitted => {
          if (isPermitted || !this._hideTemplate) {
            // only create view if the permission condition is matched
            this.viewContainerRef.createEmbeddedView(this.template);
          }
          if (this._disableEvents && !isPermitted && this.permissions.length > 0) {
            this.disablePointerEvents();
          }
          this.cdf.detectChanges();
        }).catch((err) => this.handlePermissionException(err));
    }

  }

  /**
   * This will check if the pointer events needs to be disabled
   */
  @Input()
  set hasAnyPermissionDisableEvent(disableEvent: boolean) {
    this._disableEvents = disableEvent;
  }

  /**
   * If we want to hide the template altogether, use this parameter while using directive
   * @howtouse
   * <some-element *hasAnyPermission="['PERM_EDIT', 'PERM_ADD']; ofUser: 3, hideTemplate: true">...</some-element>
   */
  @Input()
  set hasAnyPermissionHideTemplate(hideTemplate: boolean) {
    this._hideTemplate = hideTemplate;
  }

  private disablePointerEvents() {
    this.template.elementRef.nativeElement.parentElement.style = 'text-decoration:none;pointer-events: none; opacity: 0.5;  background-color: white ; color:#4b3f72 !important ';
  }

  private handlePermissionException(err): void {
    console.error({ err });
    this.router.navigateByUrl(APP_ROUTES.FORBIDDEN)
  }

}
