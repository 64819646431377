export class TreeViewStructure {
  data?: TreeNode[];
}

export class TreeNode {
  label?: string;
  data?: string;
  expandedIcon?: string;
  collapsedIcon?: string;
  icon?: string
  selectable?: boolean;
  expanded: boolean = true;
  children?: TreeNode[] = [];
}

export class PNGTree {
  children?: PNGTree[];
  collapsedIcon?: string;
  expanded: boolean = true;
  expandedIcon?: string;
  label?: string;
  parent?: PNGTree;
  partialSelected?: boolean;
  selectable?: boolean;
  selected?:boolean
}
