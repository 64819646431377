// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns phone Number in format (999)456-456
 */
@Pipe({
  name: 'phoneMask'
})
export class PhoneMaskPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param value: any
   * @param args: any
   */
  transform(value: string, args?: string) {
    if (value) {
      return value.replace(/\D+/g, '').replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    return value;
  }
}
