import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { APP_ROUTES } from '@shared/constants/routes.constant'

/**
 * @howtouse
 * Define the routes path like below
 * {
      path: 'route-path',
      loadChildren: 'lazy-loaded-modules,
      canActivate: [PermissionAuthGuard], // @thisguard @mandatory
      data: {
        permissionModules: [PermissionModule.VIEW_CLIENT]
        // @mandatory
      }
   }
 */
@Injectable({ providedIn: 'root' })
export class PermissionAuthGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) { }

  /**
   * @whatitdoes This checks if the current user has the permission to view module.
   * Module permissions are define in the data.permissionModules passed in the route data
   */
  async canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, stateSnapshot: RouterStateSnapshot): Promise<boolean> {
    const isActionPermitted = await this.authService.isPermittedAction(activatedRouteSnapshot.data.permissionModules);
    if (isActionPermitted) {
      return true;
    }
    this.router.navigate([APP_ROUTES.FORBIDDEN]);
  }

}
