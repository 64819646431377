import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes, fas } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class FontAwesomeLibModule {
  constructor(readonly faLibrary: FaIconLibrary) {
    faLibrary.addIconPacks(fas);
    faLibrary.addIcons(faTimes);
  }
}
