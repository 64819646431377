<div class="card-header custom">
  <div class="card-title">
    <h3 class="card-label" *ngIf="cardTitle" [class]="cardLabelClass">
      {{ cardTitle }}
    </h3>
    <p class="text-muted" *ngIf="cardSubTitle">{{ cardSubTitle }}</p>
  </div>
  <div class="card-toolbar">
    <ng-container *ngFor="let button of buttons">
      <button
        [title]="button?.title ? button.title : ''"
        *ngIf="!button?.isSwitcherButton"
        [type]="button?.btnType || 'button'"
        [class]="button?.btnClass"
        [ngClass]="{ 'f-s-20': button?.btnSvg }"
        (click)="onAction(button)"
        [isSubmitting]="button?.loading"
        [disabled]="button?.loading"
      >
        <ng-container
          *hasAnyPermission="button?.permissions || []; disableEvent: true"
        >
          <ng-container *ngIf="button?.btnText">{{
            button?.btnText
          }}</ng-container>
          <ng-container *ngIf="button?.btnSvg">
            <a title="Filter" class="btn btn-icon btn-sm icon-background">
              <span
                [inlineSVG]="
                  'assets/media/svg/icons/' + button?.btnSvg + '.svg'
                "
                cacheSVG="true"
                class="svg-icon svg-icon-md"
              >
              </span>
            </a>
          </ng-container>
          <ng-container *ngIf="button?.btnIcon">
            <fa-icon [icon]="button?.btnIcon"></fa-icon>
          </ng-container>
        </ng-container>
      </button>
    </ng-container>
    <div *ngIf="showSplitButton" class="split-button-wrapper">
      <p-splitButton
        icon="pi pi-filter"
        [model]="splitButtonDropDownOption.options"
        (onClick)="onClickOfSplitButton()"
        appendTo="body"
      ></p-splitButton>
    </div>
  </div>
</div>
