/**
 * @sunflowerlab
 * @author Siddhi Shah
 */

export class LayoutConstants {
  public static readonly asideMinimize: string = 'aside-minimize';
  public static readonly asideMinimizeHover: string = 'aside-minimize-hover';
  public static readonly containerFluid: string = 'container-fluid';
  public static readonly headerSelfFixedDesktop: string = 'header.self.fixed.desktop';
  public static readonly asideSelfDisplay: string = 'aside.self.display';
  public static readonly coreFieldValid: string = 'core.field.valid';
  public static readonly coreFieldInvalid: string = 'core.field.invalid';
  public static readonly coreElementValidated: string = 'core.element.validated';
  public static readonly coreValidatorValidated: string = 'core.validator.validated';
  public static readonly ariaInvalid: string = 'aria-invalid';
  public static readonly ariaDescribedBy: string = 'aria-describedby';
  public static readonly pluginsIconSet: string = 'plugins.icon.set';
}
