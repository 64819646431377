// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
// NgBootstrap
import { NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// Translation
import { TranslateModule } from '@ngx-translate/core';
// Loading bar
import { LoadingBarModule } from '@ngx-loading-bar/core';
// NGRX
// Ngx DatePicker
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Material
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
// Core Module
import { AsideLeftComponent } from './aside/aside-left.component';
import { FooterComponent } from './footer/footer.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { BrandComponent } from './brand/brand.component';
import { PartialsModule } from '../partials/partials.module';
import { BaseComponent } from './base/base.component';
import { HtmlClassService } from './html-class.service';
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component';
import { SharedModule } from '@shared/shared.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  declarations: [
    BaseComponent,
    FooterComponent,

    // headers
    BrandComponent,
    HeaderMobileComponent,

    // subheader
    SubheaderComponent,

    // aside left menu components
    AsideLeftComponent,
  ],
  exports: [
    BaseComponent,
    FooterComponent,

    // headers
    BrandComponent,
    HeaderMobileComponent,

    // subheader
    SubheaderComponent,

    // aside left menu components
    AsideLeftComponent,

  ],
  providers: [
    HtmlClassService,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    PartialsModule,
    PerfectScrollbarModule,
    FormsModule,
    MatProgressBarModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    TranslateModule.forChild(),
    LoadingBarModule,
    InlineSVGModule,
    OverlayPanelModule,
 
    // ng-bootstrap modules
    NgbProgressbarModule,
    NgbTooltipModule

  ]
})
export class ThemeModule {
}
