import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { APP_ROUTES, AppConstants } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private readonly router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem(AppConstants.authenticationToken)) {
      return true;
    }

    let queryString = "";
    if (route.queryParams && route.queryParams.code) {
      Object.keys(route.queryParams).forEach(function (key, idx) {
        if(idx===0) {
          queryString += `?${key}=${route.queryParams[key]}`
        } else {
          queryString += `&${key}=${route.queryParams[key]}`
        }
      });
    }
    this.router.navigateByUrl(APP_ROUTES.LOGIN + queryString);
    
    return false;
  }

}
